import { useEffect } from 'react';

import { Navigate, useNavigate } from 'react-router-dom';

import { useAuth, useQueryParam } from '~/hooks';
import { destroySession } from '~/services/session';
import { LoadingPage } from '~/theme/components';

function SignOut() {
	const { isUserIn, signOut } = useAuth();
	const { product } = useQueryParam('product');
	const navigate = useNavigate();

	useEffect(() => {
		signOut();
		destroySession();

		return () => {
			product ? navigate(`/signin/?product=${product}`) : navigate('/signin/');
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigate]);

	if (!isUserIn) {
		return <Navigate to={product ? `/signin/?product=${product}` : '/signin/'} />;
	}

	return <LoadingPage />;
}

export default SignOut;

import { useBreakpointValue, Flex, Button, Box, Grid, AspectRatio, FlexProps, Spinner } from '@chakra-ui/react';

import { ResponseProps as UserProps } from '~/api/users/retrieve';
import { useUpdateUser } from '~/api/users/update';
import { useDevice } from '~/hooks';
import useNamespace from '~/hooks/useNamespace';
import { Photo } from '~/theme/components';
import { F } from '~/theme/icons';

export interface RightSideBarProps extends FlexProps {
	user: UserProps | undefined;
}

function RightSideBar({ user }: RightSideBarProps) {
	const { isMobileDevice } = useDevice();
	const [updateUser, { loading }] = useUpdateUser({ headers: { 'Content-Type': 'multipart/form-data' } });
	const { translate } = useNamespace('pageLayout');

	const stickies = useBreakpointValue<FlexProps>([
		{ position: 'relative', top: '0' },
		{ position: 'sticky', top: '28' },
	]);

	const onUploadPhoto = (avatar: File) => {
		const formData = new FormData();

		formData.set('avatar', avatar);

		updateUser(formData);
	};

	return (
		<Flex display="flex" className="card" padding="8" {...stickies}>
			<Photo
				src={`${user?.avatar}?v=${Date.now()}`}
				onChangeValue={onUploadPhoto}
				render={({ getRootProps, getInputProps, open, url, ref }) => {
					const openUp = (
						<Button variant="secondary" onClick={open} isFullWidth>
							{url ? translate('pageLayout@image-change-button') : translate('pageLayout@image-select-button')}
						</Button>
					);

					const preview = (
						<div {...getRootProps()}>
							<input {...getInputProps()} />
							{loading ? (
								<Flex
									width="100%"
									height="100%"
									alignItems="center"
									justifyContent="center"
									position="relative"
									zIndex="1"
									bgColor="rgba(255, 255, 255, 0.25)"
								>
									<Spinner color="purple.300" boxSize={5} />
								</Flex>
							) : (
								<>
									<Box as="canvas" ref={ref as React.RefObject<any>} width="100%" height="100%" position="relative" zIndex="1" />
									<F position="absolute" inset="0" margin="auto" width="8" height="16" color="gray.400" />
								</>
							)}
						</div>
					);

					return (
						<Flex direction="column" gridRowGap="4">
							{isMobileDevice ? (
								<>
									<Grid
										width="100%"
										alignItems={'center'}
										gridTemplateColumns="4fr 1rem 7fr"
										gridTemplateRows="1fr"
										gridTemplateAreas="
                      'preview . input'
                    "
									>
										<AspectRatio ratio={1} gridArea="preview" borderRadius="lg" overflow="hidden" bgColor="gray.100">
											{preview}
										</AspectRatio>
										<Flex gridArea="input" direction="column" justifyContent="space-between">
											<Flex color="gray.600" direction="column" gridRowGap="0.5">
												<Box textStyle="body2">{translate('pageLayout@image-mobile-title')}</Box>
												<Box textStyle="subtitle">{translate('pageLayout@image-subtitle')}</Box>
											</Flex>
										</Flex>
									</Grid>
									{openUp}
								</>
							) : (
								<>
									<AspectRatio ratio={1} borderRadius="lg" overflow="hidden" bgColor="gray.200">
										{preview}
									</AspectRatio>
									<Flex color="gray.700" direction="column" gridRowGap="1">
										<Box textStyle="label.primary">{translate('pageLayout@image-desktop-title')}</Box>
										<Box textStyle="subtitle">{translate('pageLayout@image-subtitle')}</Box>
									</Flex>
									{openUp}
								</>
							)}
						</Flex>
					);
				}}
			/>
		</Flex>
	);
}

export default RightSideBar;

/* eslint-disable max-lines */
import { useRef } from 'react';

import {
  Box,
  Button,
  Grid,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  AspectRatio,
  Skeleton,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import { Controller, FieldPath, SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, Navigate } from 'react-router-dom';
import * as Yup from 'yup';

import useLanguages from '~/api/languages/list';
import { GenderTypes, useUser } from '~/api/users/retrieve';
import { useUpdateUser } from '~/api/users/update';
import { useNavigate, useAuth, useDevice, useTitle } from '~/hooks';
import useNamespace from '~/hooks/useNamespace';
import { Select, Option, Photo, Alert, Gender, ImperativeAlertProps, GenderTypes as InputGenderTypes } from '~/theme/components';
import birthdateValidator, { BirthdateProps } from '~/utils/birthdate';
import castObject from '~/utils/castObject';
import toFormData from '~/utils/toFormData';

import { useLoggedOut } from '../Layout';
import { ProductSpan } from '../Layout/components';

export interface ProfileNavigateProps {
  canComplete: boolean;
}

export interface ProfileSignUpProps {
  birthdate: BirthdateProps;
  native_language: string;
  gender: GenderTypes;
  custom_gender: string;
}

const schema = Yup.object({
  birthdate: Yup.object({
    day: Yup.string().nullable(),
    month: Yup.string().nullable(),
    year: Yup.string().nullable(),
  }).nullable(),
  native_language: Yup.string().nullable(),
  gender: Yup.string().nullable(),
  custom_gender: Yup.string()
    .when('gender', {
      is: (value: string) => value === 'CUSTOM',
      then: Yup.string().required('Informe seu gênero'),
    })
    .nullable(),
}).required();

export default function ProfileSignUp() {
  const auth = useAuth();
  const { state } = useLocation() as { state: ProfileNavigateProps };
  const { name, ...product } = useLoggedOut();
  const { isMobileDevice } = useDevice();
  const languages = useLanguages();

  const [updateUser, { loading, error }] = useUpdateUser({
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'PATCH',
  });
  const { data: user } = useUser();
  const navigate = useNavigate();
  const { translate } = useNamespace('signUpProfile');
  const alertRef = useRef<ImperativeAlertProps>(null);

  const shouldReplaceGender = !Object.values(InputGenderTypes).includes(user?.gender as any as InputGenderTypes) && user?.gender;

  const { formState, setValue, register, handleSubmit, watch, getValues, control } = useForm<ProfileSignUpProps>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    mode: 'all',
    defaultValues: {
      gender: shouldReplaceGender ? 'CUSTOM' : user?.gender,
      custom_gender: shouldReplaceGender ? user?.gender : null,
      native_language: user?.native_language,
      get birthdate() {
        if (!user?.birthdate) return null;

        const { year, month, day } = DateTime.fromFormat(user.birthdate as unknown as string, 'yyyy-MM-dd');

        return castObject({ year, month, day }, 'string') as unknown as BirthdateProps;
      },
    } as ProfileSignUpProps,
  });

  const { isFilled, isBeeingFilled } = birthdateValidator({ ...(watch('birthdate') ?? getValues('birthdate')) });

  const isCustomGender = watch('gender') === 'CUSTOM';

  const onSubmit: SubmitHandler<ProfileSignUpProps> = async ({ birthdate, custom_gender, ...data }) => {
    const hasBirthdate = birthdate?.day && birthdate?.month && birthdate?.year;

    const updatedData = {
      ...data,
      gender: data.gender !== InputGenderTypes.CUSTOM ? data.gender : custom_gender,
      birthdate: hasBirthdate
        ? DateTime.fromObject(castObject(birthdate as BirthdateProps, 'number')).toFormat('yyyy-MM-dd')
        : null,
    };

    const user = await updateUser(toFormData(updatedData));

    if (user?.data.id) {
      navigate(product.id ? product.callback_url : '/');
    } else alertRef.current?.turnOnAlert();
  };

  const onUploadPhoto = (avatar: File) => {
    const formData = new FormData();

    formData.set('avatar', avatar);

    updateUser(formData);
  };

  const handleSkip = () => {
    navigate(product?.callback_url ? product?.callback_url : '/');
  };

  const handler =
    <K extends FieldPath<ProfileSignUpProps> = FieldPath<ProfileSignUpProps>>(type: K) =>
    (value: any) => {
      setValue(type, value, { shouldTouch: true, shouldValidate: true });
    };

  useTitle(translate('signUpProfile@title', { name }));

  if (!state) return <Navigate to="/" />;

  return (
    <>
      <Flex direction="column" justify="center" gap="2">
        <ProductSpan />
        <Box textStyle="h5" textAlign="center" color="gray.900">
          {translate('signUpProfile@profile-title')}
        </Box>
      </Flex>
      {(error || auth.error) && (
        <Alert ref={alertRef} status="error">
          {error?.message ?? auth.error?.message}
        </Alert>
      )}
      <Flex direction="column" width="100%" alignItems="flex-start" gridRowGap="4">
        <Photo
          src={`${user?.avatar}?v=${Date.now()}`}
          isMobile={isMobileDevice}
          onChangeValue={onUploadPhoto}
          render={({ getRootProps, getInputProps, open, url, ref }) => (
            <>
              <Grid
                width="100%"
                alignItems={isMobileDevice ? 'center' : 'space-between'}
                gridTemplateColumns={`${isMobileDevice ? '3fr' : '4fr'} 1rem ${isMobileDevice ? '7fr' : '6fr'}`}
                gridTemplateRows="1fr"
                gridTemplateAreas="
                    'preview . input'
                  "
              >
                <AspectRatio
                  ratio={1}
                  gridArea="preview"
                  borderRadius="lg"
                  overflow="hidden"
                  bgColor="gray.100"
                  borderWidth={url ? 'none' : '0.0625rem'}
                  borderStyle="dashed"
                  borderColor="gray.400"
                >
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Box
                      as="canvas"
                      ref={ref as React.RefObject<any>}
                      width="100%"
                      height="100%"
                      position="relative"
                      zIndex="1"
                    />
                  </div>
                </AspectRatio>
                <Flex gridArea="input" direction="column" justifyContent="space-between">
                  <Flex color="gray.600" direction="column" gridRowGap="0.5">
                    <Box textStyle="body2">{translate('signUpProfile@form-image-label')}</Box>
                    <Box textStyle="subtitle">{translate('signUpProfile@form-image-subtitle')}</Box>
                  </Flex>
                  {!isMobileDevice && (
                    <Button variant="secondary" data-type="secondary" onClick={open} isDisabled={loading} isFullWidth>
                      {url ? translate('signUpProfile@form-with-image-button') : translate('signUpProfile@form-no-image-button')}
                    </Button>
                  )}
                </Flex>
              </Grid>
              {isMobileDevice && (
                <Button variant="secondary" data-type="secondary" onClick={open} isDisabled={loading} isFullWidth>
                  {url ? translate('signUpProfile@form-with-image-button') : translate('signUpProfile@form-no-image-button')}
                </Button>
              )}
            </>
          )}
        />
        <FormControl display="flex" flexDirection="column" width="100%" gridRowGap={['4', '8']} as="form">
          <Flex direction="column" width="100%" alignItems="flex-start" gridRowGap="2">
            <Flex direction="column" width="100%">
              <FormLabel htmlFor="birthdate" textStyle="label.primary" color="black">
                {translate('signUpProfile@form-birthdate-label')}
              </FormLabel>
              <Grid templateColumns="2fr 5fr 3fr" gridColumnGap="4" id="birthdate">
                <Input
                  id="birthdate"
                  placeholder="Dia"
                  maxLength={2}
                  {...register('birthdate.day')}
                  isInvalid={!!(formState.errors.birthdate as unknown as BirthdateProps)?.day}
                  required
                />
                <Controller
                  control={control}
                  name="birthdate.month"
                  render={({ field: { onChange, ...field } }) => (
                    <Select placeholder="Mês" onChangeValue={onChange} {...field}>
                      <Option value="1">01 - Janeiro</Option>
                      <Option value="2">02 - Fevereiro</Option>
                      <Option value="3">03 - Março</Option>
                      <Option value="4">04 - Abril</Option>
                      <Option value="5">05 - Maio</Option>
                      <Option value="6">06 - Junho</Option>
                      <Option value="7">07 - Julho</Option>
                      <Option value="8">08 - Agosto</Option>
                      <Option value="9">09 - Setembro</Option>
                      <Option value="10">10 - Outubro</Option>
                      <Option value="11">11 - Novembro</Option>
                      <Option value="12">12 - Dezembro</Option>
                    </Select>
                  )}
                />
                <Input
                  placeholder="Ano"
                  maxLength={4}
                  {...register('birthdate.year')}
                  isInvalid={!!(formState.errors.birthdate as unknown as BirthdateProps)?.year}
                  required
                />
              </Grid>
              {formState.errors.birthdate &&
                Object.values(formState.errors.birthdate).map(({ message }) => (
                  <FormErrorMessage key={message}>{message}</FormErrorMessage>
                ))}
            </Flex>
            <Flex direction="column" width="100%" gridRowGap="2">
              <Gender isCustomGender={isCustomGender} control={control} />
            </Flex>
            <Flex direction="column" width="inherit">
              <FormLabel htmlFor="native_language" textStyle="label.primary" color="black">
                {translate('signUpProfile@form-lenguage-label')}
              </FormLabel>
              <Skeleton isLoaded={!!languages.data}>
                <Select
                  id="native_language"
                  data-testid="native_language"
                  defaultValue={user?.native_language}
                  onChangeValue={handler('native_language')}
                  searchable={translate('signUpProfile@form-lenguage-select-placeholder')}
                >
                  {languages.data?.results?.map(({ code, name }) => (
                    <Option key={code} value={code}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </Skeleton>
            </Flex>
          </Flex>
          <Flex direction="column" width="100%" gridRowGap="6" my={['4', 'auto']}>
            <Button
              type="submit"
              onClick={handleSubmit(onSubmit)}
              isDisabled={
                isBeeingFilled ? !(formState.isValid && formState.isDirty && isFilled) : !(formState.isValid && formState.isDirty)
              }
              isLoading={loading}
              isFullWidth
              data-type={name.toLowerCase()}
            >
              {translate('signUpProfile@form-send-button')}
            </Button>
            <Button variant="terciary" isDisabled={loading} onClick={handleSkip}>
              {translate('signUpProfile@form-skip-button')}
            </Button>
          </Flex>
        </FormControl>
      </Flex>
    </>
  );
}

import { VerificationProps } from '~/api/validation/verifyUser';
import { ValidationTypes } from '~/context/features/auth';

type Verifications = Array<VerificationProps>;

const findValidation = (verifications: Verifications, type: ValidationTypes) =>
	verifications?.some((verification) => verification.type_validation === type && !verification.active);

const mustValidateEmail = (verifications: Verifications) => {
	const hasPendingValidation = findValidation(verifications, ValidationTypes.EMAIL);
	const isEmpty = !verifications.length;

	return isEmpty || hasPendingValidation;
};

const mustValidatePhone = (verifications: Verifications) => {
	const hasPendingValidation = findValidation(verifications, ValidationTypes.PHONE);
	const verifiedEmailOnly = verifications.length === 1;

	return verifiedEmailOnly || hasPendingValidation;
};

const getValidation = (verifications: Verifications) => {
	if (mustValidateEmail(verifications)) return ValidationTypes.EMAIL;

	if (mustValidatePhone(verifications)) return ValidationTypes.PHONE;

	return null;
};

export default getValidation;

import { createIcon } from '@chakra-ui/icons';

const Home = createIcon({
	displayName: 'Home',
	viewBox: '0 0 24 24',
	path: (
		<path
			d="M21.5812 12.4801L12.5812 2.48007C12.2022 2.05807 11.4742 2.05807 11.0952 2.48007L2.09523 12.4801C1.96568 12.6237 1.88065 12.8018 1.85047 12.9928C1.82029 13.1839 1.84626 13.3795 1.92523 13.5561C2.08523 13.9171 2.44323 14.1491 2.83823 14.1491H4.83823V21.1491C4.83823 21.4143 4.94358 21.6686 5.13112 21.8562C5.31866 22.0437 5.57301 22.1491 5.83823 22.1491H8.83823C9.10344 22.1491 9.3578 22.0437 9.54533 21.8562C9.73287 21.6686 9.83823 21.4143 9.83823 21.1491V17.1491H13.8382V21.1491C13.8382 21.4143 13.9436 21.6686 14.1311 21.8562C14.3187 22.0437 14.573 22.1491 14.8382 22.1491H17.8382C18.1034 22.1491 18.3578 22.0437 18.5453 21.8562C18.7329 21.6686 18.8382 21.4143 18.8382 21.1491V14.1491H20.8382C21.0319 14.1499 21.2216 14.0944 21.3843 13.9892C21.5469 13.8841 21.6754 13.7339 21.7542 13.557C21.833 13.38 21.8586 13.184 21.8278 12.9928C21.7971 12.8016 21.7114 12.6234 21.5812 12.4801Z"
			fill="currentColor"
		/>
	),
});

export default Home;

import createEndpoint from '~/api/createEndpoint';
import { SessionProps, UserAttributesProps } from '~/context/features/auth';

export type AuthStateTypes = 'AUTHENTICATED' | 'NEW_PASSWORD_REQUIRED';

export interface RequestProps {
	email: string;
	password: string;
}

export interface ResponseProps {
	auth_state: AuthStateTypes;
	session: SessionProps;
	user_attributes?: UserAttributesProps;
	session_token?: string;
}

const signIn = createEndpoint<'POST', RequestProps, ResponseProps>({ path: '/auth/sign-in/' });

export default signIn;

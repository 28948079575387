import { Flex, FormLabel, Input } from '@chakra-ui/react';
import { StringOrNumber } from '@chakra-ui/utils';
import { Controller, Control } from 'react-hook-form';

import { useNamespace } from '~/hooks';
import { Select, Option } from '~/theme/components';

export interface GenderProps {
	control: Control<any>;
	isCustomGender: boolean;
}

export enum GenderTypes {
	FEMALE = 'FEMALE',
	MALE = 'MALE',
	CUSTOM = 'CUSTOM',
	UNSPECIFIED = 'UNSPECIFIED',
}

const Gender = ({ control, isCustomGender }: GenderProps) => {
	const { translate } = useNamespace('genderInput');

	return (
		<>
			<Flex direction="column" width="inherit">
				<FormLabel htmlFor="gender" textStyle="label.primary" color="black">
					{translate('genderInput@gender-label')}
				</FormLabel>
				<Controller
					control={control}
					name="gender"
					render={({ field: { onChange, value, ...field } }) => (
						<Select onChangeValue={onChange} value={value as any as StringOrNumber} id="gender" {...field}>
							<Option value={GenderTypes.FEMALE}>{translate('genderInput@female-option')}</Option>
							<Option value={GenderTypes.MALE}>{translate('genderInput@male-option')}</Option>
							<Option value={GenderTypes.CUSTOM}>{translate('genderInput@custom-option')}</Option>
							<Option value={GenderTypes.UNSPECIFIED}>{translate('genderInput@unespecified-option')}</Option>
						</Select>
					)}
				/>
			</Flex>
			{isCustomGender && (
				<Flex direction="column" width="inherit">
					<FormLabel htmlFor="custom_gender" textStyle="label.primary" color="black">
						{translate('genderInput@custom-gender-label')}
					</FormLabel>
					<Controller
						control={control}
						name="custom_gender"
						render={({ formState, field }) => (
							<Input
								id="custom_gender"
								type="text"
								isInvalid={!!formState.errors.custom_gender}
								maxLength={32}
								required
								{...field}
							/>
						)}
					/>
				</Flex>
			)}
		</>
	);
};

export default Gender;

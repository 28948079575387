import { createIcon } from '@chakra-ui/icons';

const MoreVert = createIcon({
	displayName: 'MoreVert',
	viewBox: '0 0 4 18',
	path: (
		<>
			<path
				d="M4 2C4 3.10457 3.10457 4 2 4C0.89543 4 0 3.10457 0 2C0 0.89543 0.89543 0 2 0C3.10457 0 4 0.89543 4 2Z"
				fill="currentColor"
			/>
			<path
				d="M4 9C4 10.1046 3.10457 11 2 11C0.89543 11 0 10.1046 0 9C0 7.89543 0.89543 7 2 7C3.10457 7 4 7.89543 4 9Z"
				fill="currentColor"
			/>
			<path
				d="M4 16C4 17.1046 3.10457 18 2 18C0.89543 18 0 17.1046 0 16C0 14.8954 0.89543 14 2 14C3.10457 14 4 14.8954 4 16Z"
				fill="currentColor"
			/>
		</>
	),
});

export default MoreVert;

import { AppComponent, Button } from '@fluencyacademy/core-components-web';
import { useAtom } from 'jotai';
import { twMerge } from 'tailwind-merge';

import { userProductsAtom } from '../atoms/user-products-atom';
import { useNavigation } from '../hooks/use-navigation';
import { useTranslations } from '../hooks/use-translations';
import { ArrowDiagnonalIcon } from '../icons/arrow-diagonal-icon';
import { HomeProduct } from '../types/product';

type ProductRedirectButtonProps = {
  product: HomeProduct;
  iconClassName?: string;
};

const ProductRedirectButton: AppComponent<ProductRedirectButtonProps> = ({ product, className, iconClassName }) => {
  const t = useTranslations('productsHub');
  const [userProducts] = useAtom(userProductsAtom);

  let colorClass: string | undefined;
  let redirectLink: string;
  let label: string = t('product_redirect_button.label');

  switch (product) {
    case HomeProduct.COURSES:
      colorClass = 'bg-brand-primary-cursos-700';
      if (!userProducts || userProducts.includes(HomeProduct.COURSES)) {
        redirectLink = `https://academy${import.meta.env.VITE_APP_DOMAIN}`;
      } else {
        redirectLink = 'https://hub.fluency.io/br/fa/lista-de-espera';
        label = t('product_redirect_button.label2');
      }
      break;
    case HomeProduct.MEMORIZATION:
      colorClass = 'bg-brand-secondary-650';
      redirectLink = 'https://memhack.io/';
      break;
    case HomeProduct.TALKS:
      colorClass = 'bg-brand-primary-talks-700';
      redirectLink = `https://talks${import.meta.env.VITE_APP_DOMAIN}`;
      break;
  }
  const { openUrl } = useNavigation(redirectLink);

  return (
    <Button
      suffix={<ArrowDiagnonalIcon className={twMerge('text-basic-white-50', [iconClassName])} />}
      className={twMerge('text-basic-white-50 z-10 shadow-xl h-[46px] md:h-[48px]', [colorClass, className])}
      onClick={openUrl}
    >
      {label}
    </Button>
  );
};

export default ProductRedirectButton;

import { useCallback, useReducer, useRef } from 'react';

import { AxiosRequestConfig } from 'axios';
import { MutatorCallback, MutatorOptions, SWRResponse, useSWRConfig } from 'swr';

import { fetcher } from '~/services/http';

const useUnfetch = <Data = any, Error = any, Payload = any>(
	url: string,
	axios?: AxiosRequestConfig<Payload>,
	swr?: MutatorOptions<Data>
) => {
	const { mutate } = useSWRConfig();
	const [, reRender] = useReducer((prev) => prev + 1, 0);
	const state = useRef({ isValidating: false, data: undefined, error: undefined } as SWRResponse<Data, Error>);

	const request = useCallback(
		async (data?: Payload, config?: typeof axios) => {
			try {
				state.current.isValidating = true;
				state.current.error = undefined;
				reRender();

				const response = await fetcher<Data, Payload>({
					...axios,
					...config,
					headers: {
						...axios?.headers,
						...config?.headers,
					},
					data,
				})(url);

				mutate(url, response, swr);

				state.current.data = response;

				return response;
			} catch (exception) {
				state.current.error = exception as Error;
			} finally {
				state.current.isValidating = false;
				reRender();
			}
		},
		[axios, mutate, swr, url]
	);

	return [
		request,
		{
			get isValidating() {
				return state.current.isValidating;
			},
			get data() {
				return state.current.data;
			},
			get error() {
				return state.current.error;
			},
			set mutate(data: Data | Promise<Data> | MutatorCallback<Data>) {
				mutate(url, data, swr);
			},
		},
	] as [typeof request, SWRResponse<Data, Error>];
};

export default useUnfetch;

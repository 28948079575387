import { useReducer, useRef, useImperativeHandle, forwardRef, useEffect } from 'react';

import {
	Button,
	Flex,
	Image,
	useDisclosure,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	IconButton,
	useBreakpointValue,
	useFormControlContext,
	useUpdateEffect,
} from '@chakra-ui/react';
import { DropzoneInputProps, DropzoneRootProps, useDropzone } from 'react-dropzone';
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop';
import type { Action } from 'redux';
import { ValuesType } from 'utility-types';

import useNamespace from '~/hooks/useNamespace';
import { ZoomIn, ZoomOut } from '~/theme/icons';

import resolveCanvas from './resolver';

import 'react-image-crop/dist/ReactCrop.css';

export enum PhotoCrop {
	MAX_FILES = 1,
	ACCEPTED_FILES = 'image/jpeg, image/png',
}

export interface StateProps {
	crop: Partial<Crop>;
	image?: HTMLImageElement;
	preview?: string;
	cropped?: PixelCrop;
	scale: number;
	rotate: number;
}

export interface ActionProps extends Action<keyof StateProps> {
	value: ValuesType<StateProps>;
}

export interface RenderProps {
	open: () => void;
	url: string | undefined;
	ref: React.RefObject<HTMLCanvasElement>;
	getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
	getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
}

export interface PhotoProps {
	onChangeValue(file: File): void;
	render(props: RenderProps): React.ReactNode;
	isMobile?: boolean;
	src?: string;
}

export const initialState: StateProps = {
	crop: {
		unit: 'px',
		x: 25,
		y: 25,
		width: 250,
		height: 250,
	},
	scale: 1,
	rotate: 0,
};

const reducer: React.Reducer<StateProps, ActionProps> = (state, action) => ({ ...state, [action.type]: action.value });

const Photo = forwardRef<HTMLInputElement, PhotoProps>(({ onChangeValue, render, src, isMobile }, ref) => {
	const size = useBreakpointValue(['sm', 'xl']);
	const canvas = useRef<HTMLCanvasElement>(null);
	const [{ crop, scale, rotate, image, preview }, dispatch] = useReducer(reducer, { ...initialState, preview: src });
	const { isOpen, onOpen, onClose } = useDisclosure();
	const context = useFormControlContext();
	const { translate } = useNamespace('pageLayout');

	const { inputRef, getRootProps, getInputProps, open } = useDropzone({
		disabled: context?.isDisabled,
		accept: PhotoCrop.ACCEPTED_FILES as string,
		maxFiles: PhotoCrop.MAX_FILES as number,
		multiple: (PhotoCrop.MAX_FILES > 1) as boolean,
		onDrop([file]) {
			if (preview) URL.revokeObjectURL(preview);

			if (file) dispatch({ type: 'preview', value: URL.createObjectURL(file) });
		},
		onDropAccepted() {
			onOpen();
		},
	});

	const handler =
		<K extends keyof StateProps>(type: K) =>
		(value: StateProps[K]) => {
			dispatch({ type, value });
		};

	const onImageLoad = ({ currentTarget }: React.SyntheticEvent<HTMLImageElement>) => {
		handler('image')(currentTarget);
	};

	const handleCrop = async () => {
		if (crop && image && canvas?.current) {
			resolveCanvas(image, canvas.current, crop as Crop, scale, rotate);

			canvas.current.toBlob((blob) => {
				if (blob) onChangeValue(new File([blob], 'pp.png', { type: 'image/png' }));
			});

			onClose();
		}
	};

	const handleZoomIn = () => {
		if (scale < 2) {
			dispatch({ type: 'scale', value: scale + 0.1 });
		}
	};

	const handleZoomOut = () => {
		if (scale > 0.5) {
			dispatch({ type: 'scale', value: scale - 0.1 });
		}
	};

	useEffect(() => {
		let image!: HTMLImageElement;

		if (src) {
			image = document.createElement('img');

			image.setAttribute('src', src);

			image.onload = () => {
				if (canvas.current) {
					canvas.current.width = image.naturalWidth;
					canvas.current.height = image.naturalHeight;

					const context = canvas.current.getContext('2d');

					if (context) context.drawImage(image, 0, 0);
				}
			};
		}

		return () => {
			if (image) image.remove();
		};
	}, [src]);

	useUpdateEffect(() => {
		if (image) {
			const sizes = [image.width, image.height] as [number, number];

			handler('crop')(centerCrop(makeAspectCrop(initialState.crop, 1, ...sizes), ...sizes));
		}
	}, [image]);

	useImperativeHandle(ref, () => inputRef.current as HTMLInputElement, [inputRef]);

	return (
		<>
			{render({ open, getRootProps, getInputProps, url: preview, ref: canvas })}
			<Modal isOpen={isOpen} onClose={onClose} size={size} isCentered>
				<ModalOverlay bgColor="#0000007f" backdropFilter="blur(0.3125rem)" />
				<ModalContent>
					<ModalHeader textStyle="h5">{translate('pageLayout@image-modal-title')}</ModalHeader>
					<ModalCloseButton color="purple.300" />
					<ModalBody
						sx={{
							'& .ReactCrop__crop-selection': {
								'layerStyle': 'cropOutline',
								'& .ReactCrop__drag-handle::after': {
									boxSize: '3',
									bgColor: 'white',
									layerStyle: 'cropOutline',
								},
							},
						}}
					>
						<ReactCrop
							crop={crop as Crop}
							aspect={1}
							onChange={handler('crop')}
							onComplete={handler('crop')}
							minHeight={200}
							minWidth={200}
						>
							<Image
								src={preview as string}
								onLoad={onImageLoad}
								style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
								alt="Cropped view"
							/>
						</ReactCrop>
					</ModalBody>
					<ModalFooter display="flex" justifyContent="space-between">
						{!isMobile && (
							<Flex direction="row" gridColumnGap="2">
								<IconButton
									aria-label="zoom in pan crop"
									icon={<ZoomIn />}
									boxSize="10"
									variant="secondary"
									onClick={handleZoomIn}
									isRound
								/>
								<IconButton
									aria-label="zoom out pan crop"
									icon={<ZoomOut />}
									boxSize="10"
									variant="secondary"
									onClick={handleZoomOut}
									isRound
								/>
							</Flex>
						)}
						<Button onClick={handleCrop} isFullWidth={isMobile}>
							{translate('pageLayout@image-modal-button')}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
});

Photo.displayName = 'Photo';

Photo.defaultProps = {
	isMobile: false,
};

export default Photo;

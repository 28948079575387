import { ValidationTypes } from '~/context/features/auth';

import { createEndpoint } from '..';

export interface VerificationProps {
	type_validation: ValidationTypes;
	active: boolean;
}

export interface RequestProps {
	email: string;
}

export interface ResponseProps {
	verifications: Array<VerificationProps>;
}

const verify = createEndpoint<'GET', RequestProps, ResponseProps>({ path: '/users/verify/active/' });

export default verify;

import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as RouterProvider } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { SWRConfig } from 'swr';

import { componentWillMount } from '~/services/session';
import { LoadingPage } from '~/theme/components';
import Fonts from '~/theme/fonts';

import store, { persistor } from '../context';
import theme from '../theme';

export const Provider: React.ComponentType<React.PropsWithChildren<unknown>> = ({ children }) => (
	<ReduxProvider store={store}>
		<PersistGate onBeforeLift={componentWillMount} persistor={persistor} loading={<LoadingPage />}>
			<SWRConfig value={{ revalidateOnFocus: false, revalidateOnReconnect: false }}>
				<ChakraProvider theme={theme} resetCSS>
					<Fonts />
					<ColorModeScript initialColorMode={theme.config.initialColorMode} />
					<RouterProvider>{children}</RouterProvider>
				</ChakraProvider>
			</SWRConfig>
		</PersistGate>
	</ReduxProvider>
);

const withProvider =
	<T,>(Component: React.ComponentType<T>) =>
	(props: React.PropsWithChildren<T>) =>
		(
			<Provider>
				<Component {...props} />
			</Provider>
		);

export default withProvider;

import { useMemo, useState } from 'react';

import { useAtom } from 'jotai';

import { userProductsAtom } from '../atoms/user-products-atom';
import { fetchApiData, fetchLocalData, saveLocalData } from '../data/platforms-repository';
import { mapProductsFromPlatforms } from '../utils/platform-utils';

const usePlatforms = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [userProducts, setUserProducts] = useAtom(userProductsAtom);

  useMemo(() => {
    const loadData = async () => {
      setIsLoading(true);
      try {
        let data = fetchLocalData();

        if (!data) {
          data = await fetchApiData();
          saveLocalData(data);
        }

        const products = mapProductsFromPlatforms(data.platforms);

        setUserProducts(products);
      } catch (error) {
        console.error('Failed to fetch platforms', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [setUserProducts]);

  return { isLoading, userProducts };
};

export default usePlatforms;

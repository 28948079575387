import { createIcon } from '@chakra-ui/icons';

const Search = createIcon({
	displayName: 'Search',
	viewBox: '0 0 24 24',
	path: (
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.0015 4C7.13489 4 4 7.13378 4 11C4 14.8662 7.13489 18 11.0015 18C12.5742 18 14.0258 17.4815 15.1947 16.6062L18.2955 19.7071C18.686 20.0976 19.3192 20.0976 19.7097 19.7071C20.1003 19.3165 20.1003 18.6834 19.7097 18.2928L16.609 15.1921C17.4844 14.0236 18.0029 12.5724 18.0029 11C18.0029 7.13378 14.868 4 11.0015 4ZM6 11C6 8.23881 8.239 6 11.0015 6C13.7639 6 16.0029 8.23881 16.0029 11C16.0029 13.7612 13.7639 16 11.0015 16C8.239 16 6 13.7612 6 11Z"
			fill="currentColor"
		/>
	),
});

export default Search;

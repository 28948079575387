import activate from './activate.json';
import address from './address.json';
import changePassword from './changePassword.json';
import contact from './contact.json';
import forgotPassword from './forgotPassword.json';
import genderInput from './genderInput.json';
import languageMenu from './languageMenu.json';
import outdoor from './outdoor.json';
import pageLayout from './pageLayout.json';
import password from './password.json';
import productsHub from './productsHub.json';
import profile from './profile.json';
import provisional from './provisional.json';
import select from './select.json';
import signIn from './signIn.json';
import signUp from './signUp.json';
import signUpProfile from './signUpProfile.json';
import validate from './validate.json';

export default {
	activate,
	signIn,
	contact,
	pageLayout,
	signUp,
	password,
	languageMenu,
	forgotPassword,
	address,
	provisional,
	profile,
	changePassword,
	signUpProfile,
	validate,
	genderInput,
	select,
	outdoor,
	productsHub,
};

import { Heading, Icon } from '@fluencyacademy/core-components-web';
import { useAtom } from 'jotai';
import { twMerge } from 'tailwind-merge';

import { selectedProductAtom } from '../atoms/selected-product-atom';
import { useTranslations } from '../hooks/use-translations';
import { HomeProduct } from '../types/product';

const NavbarTitle = () => {
  const t = useTranslations('productsHub');
  const [selectedProduct] = useAtom(selectedProductAtom);

  const color =
    selectedProduct === HomeProduct.COURSES
      ? 'text-brand-primary-700'
      : selectedProduct === HomeProduct.MEMORIZATION
      ? 'text-brand-primary-mem-600'
      : 'text-brand-primary-talks-600';

  return (
    <span className="flex flex-row items-center justify-center">
      <Icon
        icon="star"
        size={24}
        className={twMerge('inline mr-1 transition-all duration-300 w-[18px] h-[18px] md:w-[24px] md:h-[24px]', [color])}
      />
      <Heading variant="h5" className="text-basic-white-50 md:text-[1.5rem] font-medium md:font-bold">
        {t('navbar.title')}
      </Heading>
      <Icon
        icon="star"
        size={24}
        className={twMerge('inline ml-1 transition-all duration-300 w-[18px] h-[18px] md:w-[24px] md:h-[24px]', [color])}
      />
    </span>
  );
};

export default NavbarTitle;

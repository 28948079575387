import useMutate from '~/hooks/useMutateApi';

export interface ResponseProps {
	active: boolean;
}

export interface RequestProps {
	code: string;
}

const useValidatePhoneCode = () => useMutate<ResponseProps, RequestProps>('/users/me/verify/phone-number/', { method: 'PUT' });

export { useValidatePhoneCode };

import {
	Drawer as ChakraDrawer,
	DrawerOverlay,
	DrawerContent,
	DrawerHeader,
	DrawerBody,
	DrawerFooter,
	DrawerProps,
} from '@chakra-ui/react';

import useNamespace from '~/hooks/useNamespace';

import SignOut from '../SignOut';

const Drawer: React.FC<DrawerProps> = ({ children, ...props }) => {
	const { translate } = useNamespace('pageLayout');

	return (
		<ChakraDrawer placement="bottom" {...props}>
			<DrawerOverlay bgColor="#0000007f" backdropFilter="blur(0.3125rem)" />
			<DrawerContent borderTopLeftRadius="3xl" borderTopRightRadius="3xl" py="6" zIndex="sticky" gridRowGap="4">
				<DrawerHeader textStyle="h6">{translate('pageLayout@products-title')}</DrawerHeader>
				<DrawerBody px="0">{children}</DrawerBody>
				<DrawerFooter justifyContent="flex-start" py="0">
					<SignOut />
				</DrawerFooter>
			</DrawerContent>
		</ChakraDrawer>
	);
};

export default Drawer;

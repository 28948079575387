const fadeColor = (color: string, percentual: number) => {
	const pounded = /^#/.test(color);
	const num = parseInt(color.slice(pounded ? 1 : 0), 16);
	const colorSynthesis = {
		red: (num >> 16) + percentual,
		blue: ((num >> 8) & 0x00ff) + percentual,
		green: (num & 0x0000ff) + percentual,
	};

	if (colorSynthesis.red > 255) colorSynthesis.red = 255;
	else if (colorSynthesis.red < 0) colorSynthesis.red = 0;

	if (colorSynthesis.blue > 255) colorSynthesis.blue = 255;
	else if (colorSynthesis.blue < 0) colorSynthesis.blue = 0;

	if (colorSynthesis.green > 255) colorSynthesis.green = 255;
	else if (colorSynthesis.green < 0) colorSynthesis.green = 0;

	return (pounded ? '#' : '') + (colorSynthesis.green | (colorSynthesis.blue << 8) | (colorSynthesis.red << 16)).toString(16);
};

export default fadeColor;

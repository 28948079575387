import { useRef, useState } from 'react';

import {
	Box,
	Flex,
	Button,
	FormLabel,
	FormControl,
	useDisclosure,
	PinInput,
	PinInputField,
	FormErrorMessage,
	Input,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useElapsedTime } from 'use-elapsed-time';
import * as Yup from 'yup';

import forgotPassword from '~/api/auth/forgotPassword';
import { useLoggedOut } from '~/auth/Layout';
import { useNavigate, useTitle } from '~/hooks';
import useNamespace from '~/hooks/useNamespace';
import { Alert, ImperativeAlertProps } from '~/theme/components';
import { ArrowLeft } from '~/theme/icons';
import * as Obfuscate from '~/utils/obfuscate';

import { ForgotConfig } from '..';

export interface ForgotPasswordProps {
	email: string;
}

const schema = Yup.object({
	email: Yup.string().required('forgotPassword@validation-email-required').email('forgotPassword@validation-email-invalid'),
}).required();

function ForgotPassword() {
	const navigate = useNavigate();
	const { goBack, ...product } = useLoggedOut();
	const { onOpen, isOpen } = useDisclosure();
	const { elapsedTime, reset } = useElapsedTime({ isPlaying: isOpen, duration: ForgotConfig.duration, updateInterval: 1 });
	const [code, setCode] = useState<string>();
	const [error, setError] = useState<Error>();
	const [loading, setLoading] = useState(false);
	const alertRef = useRef<ImperativeAlertProps>(null);
	const { translate } = useNamespace('forgotPassword');

	const seconds = Math.round(ForgotConfig.duration - elapsedTime);

	const { formState, register, handleSubmit, getValues } = useForm<ForgotPasswordProps>({
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		criteriaMode: 'all',
		mode: 'all',
	});

	const onSubmit: SubmitHandler<ForgotPasswordProps> = async ({ email }) => {
		if (isOpen) {
			navigate(`reset/${product.id ? `?product=${product.name.toLowerCase()}` : ''}`, { state: { email, code } });
		} else {
			try {
				setLoading(true);
				await forgotPassword.post({ email });
				onOpen();
			} catch (error) {
				setError(error as Error);
				alertRef.current?.turnOnAlert();
			} finally {
				setLoading(false);
			}
		}
	};

	const handleResend: React.MouseEventHandler<HTMLButtonElement> = async () => {
		try {
			setLoading(true);
			await forgotPassword.post({ email: getValues('email') });
			reset();
		} catch (error) {
			setError(error as Error);
			alertRef.current?.turnOnAlert();
		} finally {
			setLoading(false);
		}
	};

	useTitle(`${translate('forgotPassword@title')} | ${product.name}`);

	return (
		<>
			{error?.message && (
				<Alert ref={alertRef} status="error">
					{error?.message}
				</Alert>
			)}
			<Flex direction="column" width="100%" alignItems="flex-start" gridRowGap="2">
				<Button variant="terciary" data-type="terciary" leftIcon={<ArrowLeft />} onClick={goBack}>
					{translate('forgotPassword@go-back-button')}
				</Button>
				<Box textStyle="h5" color="gray.900">
					{translate('forgotPassword@forgot-password-title')}
				</Box>
				<Box textStyle="body2" color="gray.700">
					{isOpen
						? translate('forgotPassword@insert-email-subtitle', { email: Obfuscate.email(getValues('email')) })
						: translate('forgotPassword@insert-code-subtitle')}
				</Box>
			</Flex>
			<Flex as="form" direction="column" gridRowGap={['4', '8']} width="100%">
				<FormControl isInvalid={formState.isDirty}>
					<Flex direction="column" width="100%" alignItems="flex-start">
						{isOpen ? (
							<Flex justifyContent="space-between" width="100%">
								<PinInput placeholder="" onChange={setCode} value={code} otp>
									<PinInputField data-testid="pin" required />
									<PinInputField data-testid="pin" required />
									<PinInputField data-testid="pin" required />
									<PinInputField data-testid="pin" required />
									<PinInputField data-testid="pin" required />
									<PinInputField data-testid="pin" required />
								</PinInput>
							</Flex>
						) : (
							<>
								<Flex direction="column" width="inherit">
									<FormLabel htmlFor="email" textStyle="label.primary" color="black">
										{translate('forgotPassword@form-email-label')}
									</FormLabel>
									<Input
										type="email"
										id="email"
										data-testid="email"
										isInvalid={!!formState.errors.email}
										{...register('email')}
										required
									/>
									{formState.errors.email && (
										<FormErrorMessage>
											{translate(formState.errors.email.message as 'forgotPassword@validation-email-invalid')}
										</FormErrorMessage>
									)}
								</Flex>
								{/* <Button as={NavLink} to="/signin/forgot-email" marginTop="1" variant="terciary">
								Esqueci meu e-mail
							</Button> */}
							</>
						)}
					</Flex>
				</FormControl>
				<Flex direction="row" width="100%" gap="5" my={['4', 'auto']}>
					{isOpen && (
						<Button
							variant="secondary"
							data-type="secondary"
							onClick={handleResend}
							isDisabled={!(elapsedTime === ForgotConfig.duration || seconds === 0) || loading}
							isFullWidth
						>
							{translate('forgotPassword@form-resend-code-button', {
								seconds: seconds ? `(${String(seconds).padStart(2, '0')}s)` : '',
							})}
						</Button>
					)}
					<Button
						type="submit"
						onClick={handleSubmit(onSubmit)}
						isDisabled={isOpen ? (code?.length ?? 0) < 6 : !formState.isValid}
						isLoading={loading}
						data-testid="submit"
						data-type="primary"
						isFullWidth
					>
						{isOpen ? translate('forgotPassword@from-continue-button') : translate('forgotPassword@form-reset-password-button')}
					</Button>
				</Flex>
			</Flex>
		</>
	);
}

export { default as ResetPassword } from './Reset';
export * from './Reset';

export default ForgotPassword;

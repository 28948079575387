import type { Crop } from 'react-image-crop';

export enum Resolver {
	RADIANS = Math.PI / 180,
}

const createPreview = async (image: HTMLImageElement, canvas: HTMLCanvasElement, crop: Crop, scale = 1, rotate = 0) => {
	const context = canvas.getContext('2d');

	if (!context) throw new Error('No 2d context');

	const scaleX = image.naturalWidth / image.width;
	const scaleY = image.naturalHeight / image.height;
	const pixelRatio = window.devicePixelRatio || 1;
	const cropX = crop.x * scaleX;
	const cropY = crop.y * scaleY;
	const rotateRads = rotate * Resolver.RADIANS;
	const centerX = image.naturalWidth / 2;
	const centerY = image.naturalHeight / 2;

	canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
	canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

	context.scale(pixelRatio, pixelRatio);
	context.imageSmoothingQuality = 'high';
	context.save();
	context.translate(-cropX, -cropY);
	context.translate(centerX, centerY);
	context.rotate(rotateRads);
	context.scale(scale, scale);
	context.translate(-centerX, -centerY);
	context.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight);
	context.restore();
};

export default createPreview;

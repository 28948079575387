import { AppComponent } from '@fluencyacademy/core-components-web';

import { useTranslations } from '../hooks/use-translations';
import { HomeProduct } from '../types/product';
import ProductTitle from './product-title';

const ProductCoursesTitle: AppComponent = () => {
  const t = useTranslations('productsHub');

  return <ProductTitle product={HomeProduct.COURSES} title={t('courses_product.title')} bgColor="bg-brand-primary-700" />;
};

export default ProductCoursesTitle;

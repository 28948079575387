import { forwardRef, useEffect, useImperativeHandle } from 'react';

import { Collapse, Alert as ChrakraAlert, AlertProps as ChakraAlertProps, AlertIcon, useBoolean } from '@chakra-ui/react';

export interface AlertProps extends ChakraAlertProps {
	duration?: number | 'permanent';
	autoDisplay?: boolean;
}

export interface ImperativeAlertProps {
	turnOnAlert: () => void;
}

const Alert = forwardRef<ImperativeAlertProps, AlertProps>(({ children, duration, autoDisplay, ...props }, ref) => {
	const [isOpen, { on, off }] = useBoolean(autoDisplay && !!children);

	useEffect(() => {
		let timeoutId!: NodeJS.Timeout;

		if (duration !== 'permanent' && isOpen) {
			timeoutId = setTimeout(() => off(), duration);
		}

		return () => {
			if (timeoutId) clearTimeout(timeoutId);
		};
	}, [duration, isOpen, off]);

	useImperativeHandle(ref, () => ({ turnOnAlert: on }), [on]);

	if (!children) return null;

	return (
		<Collapse style={{ width: '100%' }} in={isOpen}>
			<ChrakraAlert {...props}>
				<AlertIcon />
				{children}
			</ChrakraAlert>
		</Collapse>
	);
});

Alert.defaultProps = {
	duration: 5000,
	autoDisplay: true,
};

export default Alert;

import { StringMap, TOptions } from 'i18next';

import useNamespace from '~/hooks/useNamespace';
import { default as locales } from '~/languages/locales/pt';

const useTranslations = <Namespace extends keyof typeof locales>(
  namespace: Namespace
): ((content: keyof typeof locales[Namespace], options?: string | TOptions<StringMap>) => string) => {
  const { translate } = useNamespace(namespace);

  return translate;
};

export { useTranslations };

import { Provider } from '~/provider';
import Routes from '~/routes';

function App() {
	return (
		<Provider>
			<Routes />
		</Provider>
	);
}

export default App;

import { ResponseProps, useFetchProduct } from '~/api/products/retrieve';
import { purple } from '~/theme/colors';

import useQueryParam from './useQueryParam';

function useProduct() {
	const { product } = useQueryParam('product');
	// TODO: Remove default product
	const { data } = useFetchProduct(product || '');

	return {
		brand_color: purple[300],
		background_image: '/images/auth-bg.jpeg',
		name: 'Fluency Academy',
		...data,
	} as ResponseProps;
}

export default useProduct;

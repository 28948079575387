import { createEndpoint } from '~/api';

export type AuthStateTypes = 'AUTHENTICATED' | 'NEW_PASSWORD_REQUIRED';

export interface RequestProps {
	email: string;
	password: string;
	confirmation_code: string;
}

const forgotPasswordReset = createEndpoint<'POST', RequestProps, any>({ path: '/auth/password/forgot/reset/' });

export default forgotPasswordReset;

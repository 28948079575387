import { TOptions, StringMap } from 'i18next';
import { useTranslation, UseTranslationOptions, KeyPrefix } from 'react-i18next';

import i18n from '~/languages';
import { default as locales } from '~/languages/locales/pt';

const useNamespace = <Namespace extends keyof typeof locales>(ns: Namespace | undefined, options?: UseTranslationOptions) => {
	const { t, i18n: i18next } = useTranslation<Namespace, KeyPrefix<Namespace>>(ns, { i18n, ...options });

	const translate = <Locale extends typeof locales[Namespace]>(content: keyof Locale, options?: string | TOptions<StringMap>) => {
		const text = t(content as any, options);

		return text;
	};

	return { translate, i18next };
};

export default useNamespace;

import { AppComponent, Button, Icon } from '@fluencyacademy/core-components-web';
import { twMerge } from 'tailwind-merge';

import { useNavigation } from '../hooks/use-navigation';
import { useTranslations } from '../hooks/use-translations';

const SupportButton: AppComponent = ({ className }) => {
  const t = useTranslations('productsHub');
  const { openUrl } = useNavigation('https://help.fluency.io/');

  return (
    <Button
      onClick={openUrl}
      className={twMerge(
        'text-brand-primary-700 md:!text-basic-white-50 bg-brand-primary-100 md:bg-transparent w-[84px] h-[36px]',
        className
      )}
      variant={'tertiary'}
      prefix={<Icon icon="sac" className="mr-1" />}
    >
      {t('general.help')}
    </Button>
  );
};

export default SupportButton;

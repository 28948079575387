import { createIcon } from '@chakra-ui/icons';

const Instagram = createIcon({
	displayName: 'Instagram',
	viewBox: '0 0 14 14',
	path: (
		<path
			d="M9.24 7C9.24 7.44303 9.10863 7.87611 8.86249 8.24448C8.61636 8.61284 8.26652 8.89995 7.85721 9.06949C7.4479 9.23903 6.99752 9.28339 6.563 9.19696C6.12848 9.11053 5.72935 8.89719 5.41608 8.58392C5.10281 8.27065 4.88947 7.87152 4.80304 7.437C4.71661 7.00248 4.76097 6.5521 4.93051 6.14279C5.10005 5.73348 5.38716 5.38364 5.75552 5.13751C6.12389 4.89137 6.55697 4.76 7 4.76C7.59387 4.76069 8.16323 4.9969 8.58316 5.41684C9.0031 5.83677 9.23932 6.40613 9.24 7ZM14 3.92V10.08C13.9988 11.1193 13.5854 12.1157 12.8505 12.8505C12.1157 13.5854 11.1193 13.9988 10.08 14H3.92C2.88072 13.9988 1.88434 13.5854 1.14945 12.8505C0.414565 12.1157 0.00118572 11.1193 0 10.08V3.92C0.00118572 2.88072 0.414565 1.88434 1.14945 1.14945C1.88434 0.414565 2.88072 0.00118572 3.92 0H10.08C11.1193 0.00118572 12.1157 0.414565 12.8505 1.14945C13.5854 1.88434 13.9988 2.88072 14 3.92ZM10.36 7C10.36 6.33545 10.1629 5.68583 9.79374 5.13328C9.42454 4.58073 8.89978 4.15008 8.28582 3.89577C7.67186 3.64145 6.99627 3.57492 6.3445 3.70456C5.69272 3.83421 5.09403 4.15422 4.62412 4.62412C4.15422 5.09403 3.83421 5.69272 3.70456 6.3445C3.57492 6.99627 3.64145 7.67186 3.89577 8.28582C4.15008 8.89978 4.58073 9.42454 5.13328 9.79374C5.68583 10.1629 6.33545 10.36 7 10.36C7.89082 10.359 8.74487 10.0047 9.37477 9.37477C10.0047 8.74487 10.359 7.89082 10.36 7ZM11.48 3.36C11.48 3.19386 11.4307 3.03146 11.3384 2.89332C11.2461 2.75518 11.1149 2.64752 10.9615 2.58394C10.808 2.52036 10.6391 2.50373 10.4761 2.53614C10.3132 2.56855 10.1635 2.64855 10.046 2.76603C9.92855 2.88351 9.84855 3.03318 9.81614 3.19612C9.78373 3.35907 9.80036 3.52796 9.86394 3.68145C9.92752 3.83494 10.0352 3.96613 10.1733 4.05843C10.3115 4.15073 10.4739 4.2 10.64 4.2C10.8628 4.2 11.0764 4.1115 11.234 3.95397C11.3915 3.79644 11.48 3.58278 11.48 3.36Z"
			fill="currentColor"
		/>
	),
});

export default Instagram;

import { createIcon } from '@chakra-ui/icons';

const Password = createIcon({
	displayName: 'Password',
	viewBox: '0 0 24 24',
	path: (
		<path
			d="M16.2784 8.923C16.2784 7.61734 15.7486 6.36515 14.8056 5.44191C13.8626 4.51867 12.5836 4 11.25 4C9.91639 4 8.6374 4.51867 7.6944 5.44191C6.7514 6.36515 6.22163 7.61734 6.22163 8.923C5.88816 8.923 5.56835 9.05269 5.33255 9.28355C5.09675 9.51441 4.96428 9.82752 4.96428 10.154V18.769C4.96428 19.0955 5.09675 19.4086 5.33255 19.6394C5.56835 19.8703 5.88816 20 6.22163 20H16.2784C16.6118 20 16.9316 19.8703 17.1674 19.6394C17.4032 19.4086 17.5357 19.0955 17.5357 18.769V10.154C17.5357 9.82752 17.4032 9.51441 17.1674 9.28355C16.9316 9.05269 16.6118 8.923 16.2784 8.923ZM11.25 15.077C11.0013 15.077 10.7582 15.0048 10.5514 14.8695C10.3447 14.7343 10.1835 14.542 10.0884 14.3171C9.99319 14.0921 9.96829 13.8446 10.0168 13.6058C10.0653 13.3671 10.1851 13.1477 10.3609 12.9756C10.5368 12.8034 10.7608 12.6862 11.0047 12.6387C11.2486 12.5912 11.5014 12.6155 11.7312 12.7087C11.9609 12.8019 12.1573 12.9597 12.2954 13.1621C12.4336 13.3645 12.5073 13.6025 12.5073 13.846C12.5073 14.1725 12.3749 14.4856 12.1391 14.7164C11.9033 14.9473 11.5835 15.077 11.25 15.077ZM8.7353 8.923C8.72856 8.59558 8.78861 8.27013 8.91193 7.96571C9.03525 7.66128 9.21937 7.38401 9.4535 7.15012C9.68763 6.91623 9.96707 6.73042 10.2755 6.60357C10.5838 6.47672 10.915 6.41138 11.2495 6.41138C11.584 6.41138 11.9151 6.47672 12.2235 6.60357C12.5319 6.73042 12.8113 6.91623 13.0455 7.15012C13.2796 7.38401 13.4637 7.66128 13.587 7.96571C13.7104 8.27013 13.7704 8.59558 13.7637 8.923H8.7353Z"
			fill="currentColor"
		/>
	),
});

export default Password;

import http from '~/services/http';

const signOut = async (authorization: string, token?: string) => {
	const params = new URLSearchParams();

	if (token) {
		params.append('token', token);
	}

	return http({
		method: 'POST',
		url: '/auth/sign-out/global/',
		params: params,
		headers: {
			Authorization: `Bearer ${authorization}`,
		},
	});
};

export default signOut;

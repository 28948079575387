import { useCallback, useReducer, useRef } from 'react';

export interface ResponseProps<T> {
	data: T | undefined;
	error: Error | undefined;
	isLoading: boolean;
}

const initialState = { data: undefined, error: undefined, isLoading: false } as ResponseProps<any>;

const usePromise = <T>(promise: T) => {
	const [, forceRender] = useReducer((prev) => prev + 1, 0);
	const state = useRef(initialState);

	const request = useCallback(
		async (...params: Params<T>) => {
			try {
				const delegate = promise as any as (...args: Params<T>) => Promise<typeof state.current.data>;

				state.current.isLoading = true;
				forceRender();

				const data = await delegate.apply(this, params);

				state.current.data = data;

				return data;
			} catch (exception) {
				state.current.error = exception as Error;
				throw exception;
			} finally {
				state.current.isLoading = false;
				forceRender();
			}
		},
		[promise]
	) as unknown as T;

	return [
		request,
		{
			get data() {
				return state.current.data;
			},
			get error() {
				return state.current.error;
			},
			get isLoading() {
				return state.current.isLoading;
			},
		},
	] as [typeof request, ResponseProps<Awaited<Return<T>>>];
};

export default usePromise;

import { AppComponent } from '@fluencyacademy/core-components-web';

const TalksBgIcon: AppComponent = ({ className }) => (
  <svg
    className={className}
    width="109"
    height="142"
    viewBox="0 0 109 142"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.2"
      d="M54.0986 88.9776C47.4489 88.9776 41.865 86.6059 37.3471 81.8626C32.829 77.1194 30.57 71.4299 30.57 64.7939V23.7716C30.57 17.3239 32.85 11.7755 37.4099 7.12649C41.9698 2.47755 47.5282 0.153076 54.0852 0.153076C60.6422 0.153076 66.2172 2.47755 70.8102 7.12649C75.4032 11.7755 77.6997 17.3239 77.6997 23.7716V64.7939C77.6997 71.4299 75.4285 77.1194 70.8863 81.8626C66.3441 86.6059 60.7481 88.9776 54.0986 88.9776ZM54.1122 141.357C51.9382 141.357 50.0956 140.591 48.5844 139.058C47.0731 137.525 46.3174 135.655 46.3174 133.446V118.529C34.2069 117.324 23.9149 112.483 15.4412 104.008C6.96758 95.532 1.83093 85.4177 0.0312221 73.6648C-0.156173 71.367 0.499988 69.3141 1.99971 67.5059C3.49943 65.6979 5.59373 64.7939 8.2826 64.7939C9.9948 64.7939 11.5784 65.3871 13.0335 66.5736C14.4887 67.7602 15.3475 69.2952 15.6099 71.1788C17.0722 80.5585 21.4118 88.2619 28.6287 94.2889C35.8456 100.316 44.3292 103.33 54.0795 103.33C63.8299 103.33 72.3132 100.316 79.5294 94.2889C86.7458 88.2619 91.0852 80.5585 92.5474 71.1788C92.8277 69.2765 93.658 67.7367 95.0383 66.5595C96.4186 65.3824 98.1128 64.7939 100.121 64.7939C102.525 64.7939 104.507 65.6979 106.067 67.5059C107.627 69.3141 108.313 71.367 108.126 73.6648C106.326 85.4177 101.19 95.532 92.7161 104.008C84.2424 112.483 73.9878 117.324 61.9523 118.529V133.446C61.9523 135.655 61.1891 137.525 59.6629 139.058C58.1365 140.591 56.2863 141.357 54.1122 141.357Z"
      fill="#5C31B6"
    />
    <path
      d="M41.8196 39.0078C40.6544 39.0078 39.663 38.5976 38.8455 37.7771C38.0279 36.9566 37.6191 35.9648 37.6191 34.8019V27.2374C37.6191 26.0745 38.0322 25.0856 38.8582 24.2708C39.6842 23.4559 40.6799 23.0485 41.8451 23.0485C43.0104 23.0485 43.9999 23.4587 44.8136 24.2793C45.6272 25.0998 46.0341 26.0915 46.0341 27.2544V34.819C46.0341 35.9819 45.623 36.9707 44.8008 37.7856C43.9786 38.6004 42.9849 39.0078 41.8196 39.0078ZM53.2812 39.0078C52.1237 39.0078 51.1362 38.5984 50.3186 37.7795C49.5011 36.9606 49.0923 35.9676 49.0923 34.8007V11.8497C49.0923 10.6827 49.5034 9.69241 50.3256 8.87875C51.1478 8.06505 52.1415 7.6582 53.3067 7.6582C54.4642 7.6582 55.4518 8.06766 56.2693 8.88655C57.0869 9.70549 57.4956 10.6984 57.4956 11.8654V34.8164C57.4956 35.9833 57.0845 36.9736 56.2623 37.7873C55.4402 38.601 54.4465 39.0078 53.2812 39.0078ZM64.7544 39.0078C63.5891 39.0078 62.5977 38.598 61.7802 37.7783C60.9626 36.9586 60.5539 35.9679 60.5539 34.8062V21.5341C60.5539 20.3724 60.9669 19.3817 61.7929 18.562C62.619 17.7423 63.6146 17.3324 64.7799 17.3324C65.9451 17.3324 66.9346 17.7423 67.7482 18.562C68.5619 19.3817 68.9688 20.3724 68.9688 21.5341V34.8062C68.9688 35.9679 68.5577 36.9586 67.7355 37.7783C66.9133 38.598 65.9196 39.0078 64.7544 39.0078Z"
      fill="#844CFC"
    />
  </svg>
);

export { TalksBgIcon };

import { useEffect, useState } from 'react';

import { Box, Button, Center, Flex, Spinner } from '@chakra-ui/react';

import { useLoggedOut } from '~/auth/Layout';
import { useNavigate, useProduct, useQueryParam } from '~/hooks';
import useMutate, { useMutateWithException } from '~/hooks/useMutateApi';
import useNamespace from '~/hooks/useNamespace';
import { Alert } from '~/theme/components';
import ArrowLeft from '~/theme/icons/navigation/ArrowLeft';

import CodeInput from './components/CodeInput';
import CreatePassword from './components/CreatePassword';

const ENDPOINT = import.meta.env.VITE_API_URL as string;

function Code() {
  const { brand_color, callback_url } = useProduct();
  const { goBack } = useLoggedOut();
  const navigate = useNavigate();
  const query = useQueryParam('email', 'product');
  const { translate } = useNamespace('activate');
  const [signInLoading, setSignInLoading] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [passwordState, setPasswordState] = useState<'success' | 'error' | undefined>();
  const [errorVerifyCodeState, setErrorVerifyCodeState] = useState<'noError' | 'error' | undefined>('noError');
  const { auth } = useLoggedOut();

  const [resendCodeMutation, resendCodeMutationConfig] = useMutate('auth/activate/', {
    baseURL: ENDPOINT,
  });
  const [confirmCodeMutation, confirmCodeMutationConfig] = useMutateWithException('auth/activate/confirm/', {
    baseURL: ENDPOINT,
    method: 'POST',
  });

  const showResendCodeAlert = Object.keys(resendCodeMutationConfig.data as object).length > 0;
  const hash = window.location.hash.split('=');
  const code = hash[0] === '#code' && hash[1] !== undefined && hash[1];

  const resendCode = async () => {
    resendCodeMutation({ email: query.email });
  };

  const confirmCode = async (code: string) => {
    const params = new URLSearchParams(`email=${query.email}`);

    if (query.product) {
      params.append('product', query.product);
    }

    navigate(`?${params}#code=${code}`);
  };

  const createPasswordAndSignin = async (password: string) => {
    setSignInLoading(true);

    try {
      const data = await confirmCodeMutation({ email: query.email, password, confirmation_code: code });

      if (data?.status && data?.status >= 200 && data?.status < 300) {
        setPasswordState('success');
        setPassword(password);
      } else {
        setPasswordState('error');
        setSignInLoading(false);
      }
    } catch (error) {
      setSignInLoading(false);
      setPasswordState('error');

      const message = (error as Error).message;

      if (message === 'Verify Code has already expired.' || message === 'Request failed with status code 404') {
        setErrorVerifyCodeState('error');
      }
    }
  };

  useEffect(() => {
    async function signIn() {
      if (passwordState === 'success') {
        if (query.email && password) {
          const redirect = query.product ? callback_url : undefined;

          try {
            await auth.signIn(query.email, password, redirect);
          } catch {
            setSignInLoading(false);
            setPasswordState('error');
          }
        }
      }
    }

    signIn();
  }, [passwordState, auth, query.email, password]);

  if (signInLoading || auth.loading) {
    return (
      <Center width="100%" height="100%">
        <Spinner boxSize="12" thickness="0.25rem" color="purple.300" margin="auto" />
      </Center>
    );
  }

  if (passwordState === 'success') {
    return (
      <>
        <Button variant="terciary" leftIcon={<ArrowLeft />} onClick={goBack}>
          {translate('activate@back')}
        </Button>
        <Flex direction="column" alignItems="center">
          <Box as="p" textStyle="h5" color={brand_color}>
            {translate('activate@redirect-title')}
          </Box>
          <Box textStyle="body2" color="gray.700" my="4">
            {translate('activate@redirect-subtitle')}
          </Box>
          <Spinner boxSize="12" thickness="0.25rem" color={brand_color} />
        </Flex>
      </>
    );
  }

  return (
    <Flex direction="column" alignItems="flex-start" gridRowGap="8" width="100%">
      {!code ? (
        <CodeInput
          resendCode={resendCode}
          resendCodeLoading={resendCodeMutationConfig.loading}
          confirm={confirmCode}
          confirmLoading={confirmCodeMutationConfig.loading}
        />
      ) : (
        <CreatePassword loading={signInLoading} createPassword={createPasswordAndSignin} />
      )}
      {passwordState === 'error' && (
        <Alert status="error">
          {errorVerifyCodeState === 'error'
            ? translate('activate@verify-code-submit-error')
            : translate('activate@create-password-error')}
        </Alert>
      )}
      {showResendCodeAlert && <Alert status="success">{translate('activate@resend-code-success')}</Alert>}
    </Flex>
  );
}

export default Code;

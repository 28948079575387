import useFetch from '~/hooks/useFetchApi';
import { fetcher } from '~/services/http';

export interface ResponseProps {
	code: string;
	name: string;
}

const getLanguages = () => fetcher<PaginateProps<ResponseProps>, unknown>({ url: '/languages/' });
const useLanguages = () => useFetch<PaginateProps<ResponseProps>>('/languages/');

export default useLanguages;
export { getLanguages };

import { createIcon } from '@chakra-ui/icons';

const AddressBook = createIcon({
	displayName: 'AddressBook',
	viewBox: '0 0 24 24',
	path: (
		<path
			d="M20.0625 16.65V3.975C20.0625 3.36563 19.6969 3 19.0875 3H6.65625C4.58437 3 3 4.58437 3 6.65625V18.8438C3 20.9156 4.58437 22.5 6.65625 22.5H19.0875C19.575 22.5 20.0625 22.1344 20.0625 21.525V20.9156C20.0625 20.6719 19.9406 20.4281 19.6969 20.1844C19.575 19.575 19.575 17.8688 19.6969 17.3813C19.9406 17.2594 20.0625 17.0156 20.0625 16.65ZM12.1406 5.4375C12.6227 5.4375 13.094 5.58046 13.4948 5.84829C13.8957 6.11613 14.2081 6.49681 14.3926 6.94221C14.5771 7.3876 14.6253 7.8777 14.5313 8.35053C14.4372 8.82336 14.2051 9.25768 13.8642 9.59857C13.5233 9.93946 13.089 10.1716 12.6162 10.2657C12.1433 10.3597 11.6532 10.3114 11.2078 10.127C10.7624 9.94247 10.3818 9.63005 10.1139 9.2292C9.84608 8.82836 9.70312 8.35709 9.70312 7.875C9.70312 7.22853 9.95993 6.60855 10.4171 6.15143C10.8742 5.69431 11.4942 5.4375 12.1406 5.4375ZM7.875 13.725C7.875 12.5135 9.02101 11.5312 10.4344 11.5312H10.6248C11.1055 11.7324 11.6214 11.8359 12.1425 11.8359C12.6636 11.8359 13.1795 11.7324 13.6603 11.5312H13.8488C15.2621 11.5312 16.4082 12.5135 16.4082 13.725V14.4562C16.4082 14.8603 16.0273 15.1875 15.555 15.1875H8.72812C8.25586 15.1875 7.875 14.86 7.875 14.4562V13.725ZM17.5031 20.0625H6.65625C5.925 20.0625 5.4375 19.575 5.4375 18.8438C5.4375 18.1125 6.04688 17.625 6.65625 17.625H17.5031V20.0625Z"
			fill="currentColor"
		/>
	),
});

export default AddressBook;

import { default as locales } from '~/languages/locales/pt';

const passVerify = (password: string) => {
	const regExp = new RegExp(/^(?:(?=\S*\d)(?=\S*[a-z])(?=.*[\W_]).{1,})$/, 'gim');

	return regExp.test(password);
};

export const yupTest = <Namespace extends keyof typeof locales>(namespace: Namespace) =>
	[
		'old_password',
		`${namespace}@validation-password-composition`,
		(password: string) => password && password.length >= 8 && passVerify(password as string),
	] as [string, string, (password?: string) => boolean];

export default passVerify;

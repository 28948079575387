import useMutate from '~/hooks/useMutateApi';

export interface ResponseProps {
	active: boolean;
}

export interface RequestProps {
	code: string;
}

const useValidateVerificationCode = () => useMutate<ResponseProps, RequestProps>('/users/me/verify/email/', { method: 'PUT' });

export { useValidateVerificationCode };

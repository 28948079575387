import { createIcon } from '@chakra-ui/icons';

const Question = createIcon({
	displayName: 'Question',
	viewBox: '0 0 24 24',
	path: (
		<path
			d="M12.5161 2.54156C11.2536 2.49129 10.0004 2.77601 8.88359 3.3668C7.7668 3.95759 6.82624 4.83341 6.15745 5.90531C6.02695 6.09375 5.97438 6.32536 6.01074 6.55168C6.0471 6.77799 6.16958 6.98148 6.35254 7.11957L7.94278 8.32791C8.12715 8.46755 8.35877 8.52964 8.58826 8.50092C8.81776 8.47221 9.02695 8.35497 9.17123 8.17421C9.47751 7.62971 9.92077 7.1747 10.4571 6.85428C10.9934 6.53386 11.6041 6.35917 12.2287 6.3475C13.365 6.3475 14.772 7.07937 14.772 8.1813C14.772 9.00894 14.0838 9.44286 12.9618 10.073C11.6518 10.8061 9.92083 11.7189 9.92083 14.0031V14.3649C9.92083 14.6001 10.0143 14.8257 10.1806 14.992C10.3468 15.1583 10.5724 15.2517 10.8076 15.2517H13.4844C13.7196 15.2517 13.9451 15.1583 14.1114 14.992C14.2777 14.8257 14.3711 14.6001 14.3711 14.3649V14.1521C14.3711 12.569 19 12.4968 19 8.21677C19.0035 4.99018 15.6528 2.54156 12.5161 2.54156ZM12.146 16.3406C11.6397 16.3406 11.1448 16.4907 10.7239 16.772C10.3029 17.0533 9.97482 17.4531 9.78108 17.9208C9.58733 18.3885 9.53664 18.9032 9.63541 19.3998C9.73418 19.8963 9.97797 20.3524 10.336 20.7104C10.694 21.0684 11.1501 21.3122 11.6466 21.4109C12.1431 21.5097 12.6578 21.459 13.1256 21.2653C13.5933 21.0715 13.9931 20.7434 14.2743 20.3225C14.5556 19.9015 14.7057 19.4066 14.7057 18.9004C14.7051 18.2217 14.4352 17.5709 13.9553 17.091C13.4754 16.6111 12.8247 16.3412 12.146 16.3406Z"
			fill="currentColor"
		/>
	),
});

export default Question;

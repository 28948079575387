import { useState } from 'react';

import { Input, Grid, InputProps, forwardRef } from '@chakra-ui/react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

import { Select, Option } from '~/theme/components';

import countries from './countries.json';

export type PhoneInputProps = { [key in 'code' | 'number']: string | undefined };

export interface PhoneProps extends Omit<NumberFormatProps<InputProps>, 'onChange' | 'value'> {
	onChange(phone: PhoneInputProps): void;
	value: PhoneInputProps;
}

export interface TracingProps {
	country: string;
	countryCode: string;
	numberMask: string;
}

const Phone = forwardRef<PhoneProps, 'input'>(({ onChange, value: phone, isDisabled, ...props }, ref) => {
	const defaultCountry = countries.find((data) => data.country === 'BR') as TracingProps;

	const [tracing, setTracing] = useState<TracingProps>(defaultCountry);
	const [value, setValue] = useState<string | undefined>(phone?.number);

	const handleCountry: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => {
		setValue('');
		setTracing(countries.find((data) => data.country === target.attributes.getNamedItem('value')?.value) as TracingProps);
	};

	const handleInput: React.FormEventHandler<HTMLInputElement> = ({ target }) => {
		if (!target) {
			setValue('');
			onChange({ code: tracing?.countryCode, number: '' });

			return;
		}

		const value = (target as HTMLInputElement)?.value;

		setValue(value);
		onChange({ code: tracing?.countryCode, number: value });
	};

	return (
		<Grid templateColumns="4fr 6fr" gridColumnGap="4" width="100%">
			<Select data-testid="phone-countrycode" role="list" isDisabled={isDisabled} onChange={handleCountry} defaultValue={tracing.country} placeholder="DDI" searchable>
				{countries.map(({ country, countryCode }, index) => (
					<Option key={index} value={country} role="option">
						{country} ({countryCode})
					</Option>
				))}
			</Select>
			<NumberFormat placeholder="(  )" format={tracing.numberMask} mask="_" onChange={handleInput} value={value} getInputRef={ref} disabled={isDisabled} thousandSeparator={false} customInput={Input} data-testid="phone-number" required {...props} />
		</Grid>
	);
});

Phone.defaultProps = {
	isDisabled: false,
};

export default Phone;

import { AppComponent } from '@fluencyacademy/core-components-web';

const CoursesIcon: AppComponent = ({ className }) => (
  <svg className={className} width="52" height="43" viewBox="0 0 52 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.613 35.5979C5.5306 35.5979 4.5723 35.1809 3.7382 34.347C2.904 33.5131 2.4869 32.5551 2.4869 31.473V4.1248C2.4869 3.0428 2.904 2.0848 3.7382 1.2509C4.5723 0.417 5.5306 0 6.613 0H45.3869C46.4693 0 47.4276 0.417 48.2617 1.2509C49.0959 2.0848 49.513 3.0428 49.513 4.1248V31.473C49.513 32.5551 49.0959 33.5131 48.2617 34.347C47.4276 35.1809 46.4693 35.5979 45.3869 35.5979H6.613ZM28.0687 9.0339H33V5H27.8668C24.4165 5 21.3198 7.4334 21.0337 10.7651C20.8149 13.2148 22.0435 15.4033 23.9958 16.6281C22.195 17.755 21 19.7148 21 21.9359V31.0163H25.1907V22.9322C25.1907 20.5641 27.1767 18.6369 29.6171 18.6369H33V14.603H28.0687C26.4867 14.603 25.1907 13.3618 25.1907 11.8103C25.1907 10.2751 26.4867 9.0339 28.0687 9.0339ZM2.2609 38.4796C1.0122 38.4796 0 39.4916 0 40.7398C0 41.9881 1.0122 43 2.2609 43H49.7391C50.9878 43 52 41.9881 52 40.7398C52 39.4916 50.9878 38.4796 49.7391 38.4796H2.2609Z"
      fill="currentColor"
    />
  </svg>
);

export { CoursesIcon };

import { AppComponent } from '@fluencyacademy/core-components-web';

const LogoutIcon: AppComponent = ({ className }) => (
  <svg className={className} width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 5.83339C25 4.55006 23.95 3.50006 22.6667 3.50006H13.3333V5.83339H22.6667V22.1667H13.3333V24.5001H22.6667C23.95 24.5001 25 23.4501 25 22.1667V5.83339ZM8.46133 12.8334L11.42 9.87473L9.77033 8.22506L3.99533 14.0001L9.77033 19.7751L11.42 18.1254L8.46133 15.1667H16.145V12.8334H8.46133Z"
      fill="#6B47ED"
    />
  </svg>
);

export { LogoutIcon };

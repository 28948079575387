import { AppComponent } from '@fluencyacademy/core-components-web';

import { useTranslations } from '../hooks/use-translations';
import { HomeProduct } from '../types/product';
import ProductTitle from './product-title';

const ProductMemorizationTitle: AppComponent = () => {
  const t = useTranslations('productsHub');

  return (
    <ProductTitle
      product={HomeProduct.MEMORIZATION}
      title={t('memorization_product.title')}
      bgColor="bg-brand-primary-mem-600"
    />
  );
};

export default ProductMemorizationTitle;

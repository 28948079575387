import type { AxiosRequestConfig, AxiosResponse } from 'axios';

import http from '~/services/http';

import { Method } from './create';

export interface ManualConfigProps extends AxiosRequestConfig {
	path: string;
}

export interface ManualRequestProps<Transport extends 'data' | 'params', Type, Return> {
	(
		payload?: Type,
		config?: Partial<AxiosRequestConfig & (Transport extends 'data' ? { data: Type } : { params: Type })>
	): Promise<[Return, Omit<AxiosResponse<Return>, 'data'>]>;
}

export interface ManualTriggerProps<Type, Return> {
	delete: ManualRequestProps<'data', Type, Return>;
	get: ManualRequestProps<'params', Type, Return>;
	patch: ManualRequestProps<'data', Type, Return>;
	post: ManualRequestProps<'data', Type, Return>;
	put: ManualRequestProps<'data', Type, Return>;
}

const createEndpoint = <InitialMethod extends Method, Type, Return>(initialConfig: ManualConfigProps) => {
	const merge = async (requestConfig: AxiosRequestConfig & Partial<Omit<AxiosRequestConfig, 'config'>>) => {
		const config = {
			...initialConfig,
			...requestConfig,
			headers: { ...requestConfig.headers },
		};

		const { data, ...response } = await http({
			...initialConfig,
			...config,
			url: initialConfig.path,
		});

		return [data, response] as [Return, Omit<AxiosResponse<Return>, 'data'>];
	};

	const method: ManualTriggerProps<Type, Return> = {
		delete: (data?, config?) => merge({ ...config, data, method: 'delete' }),
		get: (params?, config?) => merge({ ...config, params, method: 'get' }),
		patch: (data?, config?) => merge({ ...config, data, method: 'patch' }),
		post: (data?, config?) => merge({ ...config, data, method: 'post' }),
		put: (data?, config?) => merge({ ...config, data, method: 'put' }),
	};

	return method as Pick<typeof method, Lowercase<InitialMethod>>;
};

export default createEndpoint;

import { createIcon } from '@chakra-ui/icons';

const TimesCircle = createIcon({
	displayName: 'TimesCircle',
	viewBox: '0 0 24 24',
	path: (
		<path
			d="M12 2C6.47581 2 2 6.47581 2 12C2 17.5242 6.47581 22 12 22C17.5242 22 22 17.5242 22 12C22 6.47581 17.5242 2 12 2ZM16.9032 14.625C17.0927 14.8145 17.0927 15.121 16.9032 15.3105L15.3065 16.9032C15.1169 17.0927 14.8105 17.0927 14.621 16.9032L12 14.2581L9.375 16.9032C9.18548 17.0927 8.87903 17.0927 8.68952 16.9032L7.09677 15.3065C6.90726 15.1169 6.90726 14.8105 7.09677 14.621L9.74194 12L7.09677 9.375C6.90726 9.18548 6.90726 8.87903 7.09677 8.68952L8.69355 7.09274C8.88306 6.90323 9.18952 6.90323 9.37903 7.09274L12 9.74194L14.625 7.09677C14.8145 6.90726 15.121 6.90726 15.3105 7.09677L16.9073 8.69355C17.0968 8.88306 17.0968 9.18952 16.9073 9.37903L14.2581 12L16.9032 14.625Z"
			fill="currentColor"
		/>
	),
});

export default TimesCircle;

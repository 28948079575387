import { createEndpoint } from '~/api';
import { SessionProps } from '~/context/features/auth';

export type AuthStateTypes = 'AUTHENTICATED' | 'NEW_PASSWORD_REQUIRED';

export interface RequestProps {
	email: string;
	new_password: string;
	session_token: string;
}

export interface ResponseProps {
	auth_state: AuthStateTypes;
	session: SessionProps;
}

const completeNewPassword = createEndpoint<'POST', RequestProps, ResponseProps>({ path: '/auth/password/complete/' });

export default completeNewPassword;

import { createIcon } from '@chakra-ui/icons';

const CircleQuestion = createIcon({
	displayName: 'CircleQuestion',
	viewBox: '0 0 18 18',
	path: (
		<path
			d="M9 2C7.61553 2 6.26216 2.41054 5.11101 3.17971C3.95987 3.94888 3.06266 5.04213 2.53285 6.32122C2.00303 7.6003 1.86441 9.00776 2.13451 10.3656C2.4046 11.7235 3.07129 12.9708 4.05026 13.9497C5.02922 14.9287 6.27651 15.5954 7.63437 15.8655C8.99224 16.1356 10.3997 15.997 11.6788 15.4672C12.9579 14.9373 14.0511 14.0401 14.8203 12.889C15.5895 11.7378 16 10.3845 16 9C16 7.14348 15.2625 5.36301 13.9498 4.05025C12.637 2.7375 10.8565 2 9 2ZM3.99987 1.51677C5.47991 0.527841 7.21997 0 9 0C11.387 0 13.6761 0.948212 15.364 2.63604C17.0518 4.32387 18 6.61305 18 9C18 10.78 17.4722 12.5201 16.4832 14.0001C15.4943 15.4802 14.0887 16.6337 12.4442 17.3149C10.7996 17.9961 8.99002 18.1743 7.24419 17.8271C5.49836 17.4798 3.89472 16.6226 2.63604 15.364C1.37737 14.1053 0.520204 12.5016 0.172937 10.7558C-0.17433 9.00998 0.00389955 7.20038 0.685088 5.55585C1.36628 3.91131 2.51983 2.50571 3.99987 1.51677ZM8.22652 6.19253C7.95304 6.38507 7.72492 6.62305 7.61521 6.93494C7.43194 7.45593 6.86102 7.7297 6.34003 7.54643C5.81904 7.36316 5.54527 6.79224 5.72854 6.27125C5.99499 5.51381 6.52091 4.87511 7.21315 4.46827C7.9054 4.06143 8.71929 3.91271 9.51067 4.04846C10.3021 4.1842 11.0199 4.59565 11.537 5.20992C12.0539 5.82404 12.3369 6.60128 12.3359 7.40402C12.3354 8.73436 11.3508 9.59498 10.6906 10.0351C10.3299 10.2756 9.97643 10.4516 9.71701 10.5669C9.58588 10.6252 9.47522 10.6695 9.39499 10.7001C9.3548 10.7154 9.322 10.7273 9.29784 10.7359L9.26812 10.7463L9.25835 10.7497L9.25478 10.7509L9.25333 10.7514C9.25333 10.7514 8.31623 10.9487 8 10C7.68377 9.05132 8.90473 8.73928 8.90473 8.73928C9.09532 8.65458 9.3418 8.53062 9.58117 8.37104C10.1208 8.01131 10.3359 7.67223 10.3359 7.40309L10.3359 7.4016C10.3364 7.07098 10.2198 6.75085 10.0069 6.49792C9.79399 6.24498 9.12819 5.55775 8.22652 6.19253ZM9 14C9.55228 14 10 13.5523 10 13C10 12.4477 9.55228 12 9 12C8.44771 12 8 12.4477 8 13C8 13.5523 8.44771 14 9 14Z"
			fill="currentColor"
		/>
	),
});

export default CircleQuestion;

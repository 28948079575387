import { AppComponent } from '@fluencyacademy/core-components-web';

const PlusIcon: AppComponent = ({ className }) => (
  <svg className={className} width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" width="15" height="15" rx="7.5" fill="white" />
    <path
      d="M8.56667 4.16667C8.56667 3.85371 8.31296 3.6 8 3.6C7.68704 3.6 7.43333 3.85371 7.43333 4.16667L7.43333 6.93333L4.66667 6.93333C4.35371 6.93333 4.1 7.18704 4.1 7.5C4.1 7.81296 4.35371 8.06667 4.66667 8.06667L7.43333 8.06667V10.8333C7.43333 11.1463 7.68704 11.4 8 11.4C8.31296 11.4 8.56667 11.1463 8.56667 10.8333L8.56667 8.06667H11.3333C11.6463 8.06667 11.9 7.81296 11.9 7.5C11.9 7.18704 11.6463 6.93333 11.3333 6.93333H8.56667V4.16667Z"
      fill="#844CFC"
      stroke="#844CFC"
      strokeWidth="0.3"
    />
  </svg>
);

export { PlusIcon };

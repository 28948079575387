import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import createFilter from 'redux-persist-transform-filter';
import storage from 'redux-persist/lib/storage';

import auth from './features/auth';

const reducers = combineReducers({ auth: auth.reducer });

export default persistReducer(
	{
		key: 'accounts',
		whitelist: ['auth'],
		blacklist: [],
		transforms: [createFilter('auth', ['session', 'provisional', 'authenticate'])],
		storage,
	},
	reducers
);

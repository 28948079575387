import { Global } from '@emotion/react';

export default function Fonts() {
	return (
		<Global
			styles={`
			/* GT Walsheim */
				@font-face {
					font-family: 'GT Walsheim';
					font-weight: 300;
					src: url('/fonts/GT-Walsheim-Light.woff') format('woff'), url('/fonts/GT-Walsheim-Light.woff2') format('woff2');
				}
				@font-face {
					font-family: 'GT Walsheim';
					font-weight: 400;
					src: url('/fonts/GT-Walsheim-Regular.woff') format('woff'), url('/fonts/GT-Walsheim-Regular.woff2') format('woff2');
				}
				@font-face {
					font-family: 'GT Walsheim';
					font-weight: 500;
					src: url('/fonts/GT-Walsheim-Medium.woff') format('woff'), url('/fonts/GT-Walsheim-Medium.woff2') format('woff2');
				}
				@font-face {
					font-family: 'GT Walsheim';
					font-weight: 700;
					src: url('/fonts/GT-Walsheim-Bold.woff') format('woff'), url('/fonts/GT-Walsheim-Bold.woff2') format('woff2');
				}
				`}
		/>
	);
}

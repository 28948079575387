import { createIcon } from '@chakra-ui/icons';

const F = createIcon({
	displayName: 'F',
	viewBox: '0 0 32 70',
	path: (
		<>
			<path
				d="M18.8477 11.0965H31.6009V0.349976H18.3053C9.41683 0.349976 1.40213 6.84208 0.628784 15.6984C0.357743 18.7541 0.927516 21.8258 2.27645 24.581C3.62538 27.3363 5.70211 29.6701 8.28192 31.3301C5.91598 32.8539 3.96976 34.9464 2.62124 37.4164C1.27271 39.8865 0.564889 42.6551 0.5625 45.4693V69.65H11.4095V48.1127C11.4095 46.6114 11.7052 45.1247 12.2798 43.7376C12.8544 42.3506 13.6966 41.0903 14.7584 40.0288C15.8201 38.9672 17.0805 38.1252 18.4677 37.5508C19.8549 36.9765 21.3416 36.681 22.843 36.6813H31.5868V25.9408H18.8477C16.9343 25.8575 15.1269 25.0388 13.8025 23.6554C12.478 22.2721 11.7387 20.4308 11.7387 18.5156C11.7387 16.6004 12.478 14.7592 13.8025 13.3758C15.1269 11.9924 16.9343 11.1738 18.8477 11.0905"
				fill="currentColor"
			/>
		</>
	),
});

export default F;

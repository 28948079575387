import { extendTheme } from '@chakra-ui/react';

import * as colors from './colors';
import components from './components';
import config from './config';
import breakpoints from './screen';
import * as styles from './styles';

const theme = extendTheme({
	...styles,
	config,
	components,
	colors,
	breakpoints,
	fonts: {
		heading: 'GT Walsheim, Roboto, sans-serif',
		body: 'IBM Plex Sans, Roboto, sans-serif',
		mono: 'monospace',
	},
	boxShadow: {
		lg: '0rem 0.25rem 1rem rgba(0, 0, 0, 0.12)',
	},
	fontSizes: {
		'xs': '0.625rem',
		'sm': '0.875rem',
		'md': '1rem',
		'lg': '1.125rem',
		'xl': '1.25rem',
		'2xl': '1.5rem',
		'3xl': '1.875rem',
		'4xl': '2.25rem',
		'5xl': '3rem',
		'6xl': '3.75rem',
		'7xl': '4.5rem',
		'8xl': '6rem',
		'9xl': '8rem',
	},
	lineHeights: {
		'normal': 'normal',
		'none': '1rem',
		'shorter': 1.125,
		'short': 1.375,
		'base': 1.5,
		'tall': 1.625,
		'taller': '2',
		'3': '.75rem',
		'4': '1rem',
		'5': '1.25rem',
		'6': '1.5rem',
		'7': '1.75rem',
		'8': '2rem',
		'9': '2.25rem',
		'10': '2.5rem',
	},
});

export default theme;

import { AppComponent } from '@fluencyacademy/core-components-web';

const TalksIcon: AppComponent = ({ className }) => (
  <svg className={className} width="56" height="44" viewBox="0 0 56 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M39.2181 14.4393C39.2181 24.1522 30.8129 31.4692 20.8555 32.3428C22.9165 38.7776 29.7527 43.5 37.8611 43.5C41.101 43.5 44.129 42.7475 46.7413 41.4329C47.3774 41.7789 48.0983 42.1162 48.8786 42.4189C50.4308 43.0157 52.2967 43.5 54.1457 43.5C54.7309 43.5 55.2568 43.1108 55.4349 42.54C55.613 41.9691 55.4179 41.3464 54.943 40.9918L54.926 40.9745C54.9091 40.9572 54.8751 40.9399 54.8327 40.9053C54.7479 40.8361 54.6207 40.7323 54.468 40.5853C54.1627 40.2999 53.7471 39.8847 53.34 39.3485C52.8735 38.743 52.4324 38.0165 52.1356 37.2122C54.2475 34.704 55.4943 31.6163 55.4943 28.2691C55.4943 20.2428 48.2934 13.6609 39.1587 13.0901C39.1927 13.5312 39.2096 13.9809 39.2096 14.4307L39.2181 14.4393Z"
      fill="currentColor"
    />
    <path
      d="M17.9249 29.2313C27.507 29.8744 35.6872 23.9764 36.1986 16.0541C36.71 8.13169 29.3585 1.19115 19.7764 0.547982C10.1944 -0.0951902 2.01412 5.8028 1.50274 13.7252C1.30201 16.8348 2.31587 19.7906 4.19706 22.2782C3.95927 23.9589 3.21847 25.4188 2.46693 26.5179C2.05328 27.1272 1.64086 27.6119 1.34076 27.938C1.18733 28.1009 1.07045 28.2246 0.983345 28.3088C0.940015 28.3474 0.910684 28.3801 0.889019 28.3994L0.867354 28.4187C0.53516 28.715 0.4168 29.1779 0.559509 29.603C0.702219 30.028 1.0779 30.3233 1.51838 30.3529C3.46324 30.4834 5.46128 30.0012 7.13393 29.3933C8.73027 28.808 10.1047 28.0762 10.9498 27.5304C13.0535 28.468 15.4104 29.0694 17.9245 29.2382L17.9249 29.2313ZM10.0343 16.5138C8.83484 16.4333 7.92944 15.3823 8.00822 14.1618C8.087 12.9414 9.11969 12.0205 10.3191 12.101C11.5186 12.1815 12.424 13.2325 12.3452 14.453C12.2664 15.6734 11.2337 16.5943 10.0343 16.5138ZM18.7083 17.096C17.5088 17.0155 16.6034 15.9645 16.6822 14.7441C16.761 13.5236 17.7937 12.6027 18.9931 12.6832C20.1926 12.7637 21.098 13.8148 21.0192 15.0352C20.9404 16.2556 19.9077 17.1765 18.7083 17.096ZM29.6932 15.6174C29.6144 16.8378 28.5817 17.7588 27.3822 17.6782C26.1828 17.5977 25.2774 16.5467 25.3562 15.3263C25.4349 14.1059 26.4676 13.1849 27.6671 13.2654C28.8665 13.3459 29.7719 14.397 29.6932 15.6174Z"
      fill="currentColor"
    />
  </svg>
);

export { TalksIcon };

import { AppComponent } from '@fluencyacademy/core-components-web';

const MemorizationBgIcon: AppComponent = ({ className }) => (
  <svg
    className={className}
    width="117"
    height="140"
    viewBox="0 0 117 140"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.24"
      d="M116.203 92.8485L103.984 68.1983C103.388 66.9006 103.09 65.2793 103.388 63.658C107.56 29.2771 81.6325 -1.8594 48.8505 0.0866291C23.8167 1.70795 2.95442 23.1143 0.272225 50.3582C-1.5159 69.8185 5.63661 87.3328 17.8551 99.009C19.3449 100.307 19.9416 102.253 19.9416 104.522V132.864C19.9416 136.756 22.9222 140 26.4984 140H64.6443C68.2205 140 71.2011 136.756 71.2011 132.864V135.984C71.2011 132.092 74.1816 128.848 77.7579 128.848H97.4273C101.004 128.848 103.984 125.605 103.984 121.713V110.685C103.984 106.793 106.965 103.549 110.541 103.549C115.308 103.551 118.587 97.7135 116.203 92.8485ZM89.9785 51.6565C88.4887 61.0619 78.654 65.927 71.2031 60.4136C66.7328 63.6574 60.7727 63.6574 56.5998 59.7653C49.7456 62.3597 45.2753 69.4955 45.5726 77.2796L46.1683 90.5771H34.5465C33.3541 83.766 31.2686 77.9279 24.7118 73.0629C22.0296 71.1168 19.3475 68.1978 19.0491 65.2787C16.6653 63.008 12.4923 55.2239 17.8567 47.4397C15.7702 39.6556 20.8372 33.4928 25.6049 31.8715C27.393 27.0064 32.7574 22.466 39.9099 24.0873C46.1694 18.5739 52.4278 21.493 54.2159 22.7907C62.2625 18.8986 67.6269 23.439 69.7134 27.0075C75.6735 26.0344 81.3362 31.5478 82.5286 37.3859C87.2963 39.332 90.8725 45.1701 89.9785 51.6565Z"
      fill="#B82156"
    />
  </svg>
);

export { MemorizationBgIcon };

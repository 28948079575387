import { AppComponent, Icon, Paragraph } from '@fluencyacademy/core-components-web';

import { useTranslations } from '../hooks/use-translations';

const ProductCoursesHighlightedText: AppComponent = () => {
  const t = useTranslations('productsHub');

  return (
    <span className="text-start">
      <Icon icon="star" size={13} className="inline text-highlight-feedback-green mr-1" />
      <Paragraph tag="span" variant="01_500" className="text-highlight-feedback-green text-start">
        {t('courses_product.extra_description')}
      </Paragraph>
      <Icon icon="star" size={13} className="inline text-highlight-feedback-green ml-1" />
    </span>
  );
};

export default ProductCoursesHighlightedText;

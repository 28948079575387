import { Flex, FlexProps, forwardRef } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

import { gray } from '~/theme/colors';
import fadeColor from '~/utils/fadeColor';

const Scrollbar = forwardRef<FlexProps, 'div'>((props, ref) => (
	<Flex
		ref={ref}
		overflow="auto"
		position="relative"
		sx={{
			'&::-webkit-scrollbar': {
				width: '0.5rem',
				height: '0.5rem',
			},
			'&::-webkit-scrollbar-thumb': {
				background: mode(gray[400], gray[600])(props),
				borderRadius: 10,
			},
			'&::-webkit-scrollbar-thumb:hover': {
				background: fadeColor(mode(gray[300], gray[400])(props), -0.2),
			},
			'&::-webkit-scrollbar-thumb:active': {
				background: fadeColor(mode(gray[500], gray[500])(props), -0.3),
			},
			'&::-webkit-scrollbar-track, &::-webkit-scrollbar-corner': {
				background: mode(gray[100], gray[800])(props),
				boxShadow: `inset 0 0 0.375rem ${fadeColor(mode(gray[100], gray[900])(props), -0.1)}`,
			},
		}}
		{...props}
	/>
));

Scrollbar.displayName = 'Scrollbar';

Scrollbar.defaultProps = {
	direction: 'column',
};

export default Scrollbar;

import { AppComponent, Support } from '@fluencyacademy/core-components-web';
import { twMerge } from 'tailwind-merge';

type ProductTagProps = {
  label: string;
  backgroundColor: string;
};
const ProductTag: AppComponent<ProductTagProps> = ({ label, backgroundColor }) => (
  <div className="flex flex-row items-center">
    <div className={twMerge('px-[12px] py-[11px] rounded-[8px]', backgroundColor)}>
      <Support variant="caption_01" className="!font-bold text-basic-white-50 leading-[1.125rem]">
        {label}
      </Support>
    </div>
  </div>
);

export default ProductTag;

import { useEffect, useState } from 'react';

import { Box, Button, Center, Flex, FormControl, FormErrorMessage, FormLabel, Input, Link, Spinner } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { SubmitHandler, useForm } from 'react-hook-form';
import { NavLink, useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { useDevice, useNavigate, useTitle, useQueryParam } from '~/hooks';
import useMutate from '~/hooks/useMutateApi';
import useNamespace from '~/hooks/useNamespace';
import { Alert, Password } from '~/theme/components';
import { ArrowLeft, Company } from '~/theme/icons';
import { yupTest } from '~/utils/passVerify';

import { useLoggedOut } from '../Layout';
import { ProductSpan } from '../Layout/components';

export interface SignInProps {
	email: string;
	password: string;
}

const verifySchema = Yup.object({
	email: Yup.string().required('signIn@validation-email-required').email('signIn@validation-email-invalid'),
}).required();

const schema = Yup.object({
	email: Yup.string().required('signIn@validation-email-required').email('signIn@validation-email-invalid'),
	password: Yup.string()
		.required('signIn@validation-password-required')
		.test(...yupTest('signIn')),
}).required();

const ENDPOINT = import.meta.env.VITE_API_URL;

function SignIn() {
	const navigate = useNavigate();
	const { search } = useLocation();
	const { auth, ...product } = useLoggedOut();
	const { translate } = useNamespace('signIn');
	const { translate: translateGeneral } = useNamespace('forgotPassword');
	const { isMobileDevice } = useDevice();
	const externalLink = 'https://hub.fluency.io/br/fa/lista-de-espera';
	const goToTalksLink = () => (window.location.href = `https://talks${import.meta.env.VITE_FIO_DOMAIN}/auth/login/teacher`);
	const isTalks = product.name.toLowerCase() === 'talks';
	const [verifyState, setVerifyState] = useState<'verify' | 'signin'>('verify');
	const params = useQueryParam('product');

	const {
		formState: verifyFormState,
		register: verifyRegister,
		handleSubmit: verifyHandleSubmit,
		getValues,
		setError,
		clearErrors,
		setValue: setVerifyValue,
	} = useForm<Pick<SignInProps, 'email'>>({
		resolver: yupResolver(verifySchema),
		reValidateMode: 'onChange',
		criteriaMode: 'all',
		mode: 'all',
	});

	const { formState, register, handleSubmit, setValue } = useForm<SignInProps>({
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		criteriaMode: 'all',
		mode: 'all',
	});

	const [mutate, { loading: loadingVerify, error: verifyError }] = useMutate('auth/activate/', {
		baseURL: ENDPOINT as string,
	});

	const onVerify: SubmitHandler<Pick<SignInProps, 'email'>> = async ({ email }) => {
		const response = await mutate({ email });

		if (response?.status === 201) {
			if (params.product) {
				navigate(`/activate/code?email=${email}&product=${params.product}`);

				return;
			}

			navigate(`/activate/code?email=${email}`);

			return;
		}
	};

	const onSubmit: SubmitHandler<SignInProps> = async ({ email, password }) => {
		auth.signIn(email, password, product.callback_url ?? '');
	};

	const getErrorMessage = (error: AxiosError) => {
		if (error?.response?.status === 404) {
			return translate('signIn@error-auth-404');
		}

		return error.message;
	};

	const reset = () => {
		setVerifyState('verify');
		setValue('email', '');
		setVerifyValue('email', '');
		clearErrors('email');
	};

	useTitle(`${translate('signIn@title')} | ${product.name}`);

	useEffect(() => {
		if (verifyError?.message?.includes('user already activated')) {
			setVerifyState('signin');
			setValue('email', getValues('email'));

			return;
		}

		if (verifyError?.message) {
			setError('email', verifyError);
		}
	}, [verifyError, setError, setValue, getValues]);

	if (auth.loading) {
		return (
			<Center width="100%" height="100%">
				<Spinner boxSize="12" thickness="0.25rem" color="purple.300" margin="auto" />
			</Center>
		);
	}

	return (
		<>
			{verifyState === 'signin' ? (
				<Flex width="100%" flexDirection="column" alignItems="flex-start">
					<Button variant="terciary" data-type="terciary" leftIcon={<ArrowLeft />} onClick={reset}>
						{translateGeneral('forgotPassword@go-back-button')}
					</Button>
					<Box textStyle="h5" color="gray.900" mt="4">
						{translate('signIn@signin-title')}
					</Box>
				</Flex>
			) : (
				<Flex direction="column" align="center" justify="center">
					<Flex direction="column" justify="center" gap="2">
						<ProductSpan />
						<Box textStyle="h5" textAlign="center" color="gray.900">
							{translate('signIn@signin-title')}
						</Box>
					</Flex>
				</Flex>
			)}
			{auth.error && <Alert status="error">{getErrorMessage(auth.error as AxiosError)}</Alert>}
			{verifyState === 'verify' ? (
				<FormControl
					display="flex"
					flexDirection="column"
					gridRowGap={['4', '8']}
					as="form"
					width="100%"
					isInvalid={verifyFormState.isDirty}
				>
					<Flex direction="column" width="inherit">
						<FormLabel htmlFor="email" textStyle="label.primary" color="black">
							{translate('signIn@form-email-label')}
						</FormLabel>
						<Input
							id="email"
							type="email"
							data-testid="email"
							isInvalid={!!verifyFormState.errors.email}
							required
							color="black"
							{...verifyRegister('email')}
						/>
						{verifyFormState.errors.email && <FormErrorMessage>{translate('signIn@validation-email-invalid')}</FormErrorMessage>}
					</Flex>
					<Button
						type="submit"
						isLoading={loadingVerify}
						onClick={verifyHandleSubmit(onVerify)}
						data-testid="submit"
						data-type="primary"
						isFullWidth
					>
						{translate('signIn@form-signin-button')}
					</Button>
				</FormControl>
			) : (
				<FormControl
					display="flex"
					flexDirection="column"
					gridRowGap={['4', '8']}
					as="form"
					width="100%"
					isInvalid={formState.isDirty}
				>
					<Flex direction="column" width="100%" alignItems="flex-start" gridRowGap="2">
						<Flex direction="column" width="inherit">
							<FormLabel htmlFor="email" textStyle="label.primary" color="black">
								{translate('signIn@form-email-label')}
							</FormLabel>
							<Input
								id="email"
								type="email"
								data-testid="email"
								isInvalid={!!formState.errors.email}
								disabled
								required
								color="black"
								{...register('email')}
							/>
							{formState.errors.email && (
								<FormErrorMessage>
									{translate(formState.errors.email.message as 'signIn@validation-email-invalid')}
								</FormErrorMessage>
							)}
						</Flex>
						<Flex direction="column" width="inherit" mt="2">
							<FormLabel htmlFor="password" textStyle="label.primary" color="black">
								{translate('signIn@form-password-label')}
							</FormLabel>
							<Password
								id="password"
								data-testid="password"
								isInvalid={!!formState.errors.password}
								required
								{...register('password')}
							/>
							{formState.errors.password && (
								<FormErrorMessage>
									{translate(formState.errors.password.message as 'signIn@validation-password-required')}
								</FormErrorMessage>
							)}
						</Flex>
						<Button as={NavLink} to={`forgot-password/${search}`} variant="terciary">
							{translate('signIn@form-forgot-password')}
						</Button>
					</Flex>
					<Button
						type="submit"
						isDisabled={!formState.isValid}
						isLoading={auth.loading}
						onClick={handleSubmit(onSubmit)}
						data-testid="submit"
						data-type="primary"
						isFullWidth
					>
						{translate('signIn@form-signin-button')}
					</Button>
				</FormControl>
			)}
			{isTalks && !isMobileDevice && (
				<Button mt="-4" as={Link} onClick={goToTalksLink} variant="terciary" color="gray.600">
					{translate('signIn@talks-teacher-link')}
				</Button>
			)}
			{isMobileDevice &&
				(externalLink ? (
					<Flex direction="column" alignItems="center" width="inherit" my={['4', 'auto']}>
						<Flex direction="row" alignItems="center" gridColumnGap="1">
							<Box textStyle="label.primary" color="gray.600">
								{translate('signIn@not-student')}
							</Box>
							<Button as={Link} onClick={() => navigate(externalLink)} variant="terciary">
								{translate('signIn@como-to-fluency')}
							</Button>
						</Flex>
					</Flex>
				) : (
					<Flex direction="column" alignItems="center" width="inherit" my={['4', 'auto']}>
						<Flex direction="row" alignItems="center" gridColumnGap="1">
							<Box textStyle="label.primary" color="gray.600">
								{translate('signIn@without-account')}
							</Box>
							<Button as={NavLink} to={`/signup/${search}`} variant="terciary">
								{translate('signIn@signup-link')}
							</Button>
						</Flex>
						{isTalks && (
							<Button as={Link} onClick={goToTalksLink} variant="terciary" color="gray.600">
								{translate('signIn@talks-teacher-link')}
							</Button>
						)}
					</Flex>
				))}
			<Company alignSelf="center" width="24" height="8" color="gray.300" />
			{!isMobileDevice && (
				<Flex position="absolute" bottom={-120} gap="1" align="center" justify="center" fontSize="1rem">
					<Box textStyle="label.primary" color="white">
						{translate('signIn@not-student-yet')}
					</Box>
					<Button
						as={Link}
						onClick={() => {
							if (externalLink) navigate(externalLink);
							else navigate(`/signup/${search}`);
						}}
						variant="terciary"
						color="white"
						textDecoration="underline"
					>
						{translate('signIn@como-to-fluency')}
					</Button>
				</Flex>
			)}
		</>
	);
}

export default SignIn;

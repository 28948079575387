import type { AxiosRequestConfig } from 'axios';
import { useMatch, useResolvedPath } from 'react-router-dom';
import useSWR, { BareFetcher, SWRConfiguration } from 'swr';

import { useNavigate } from '~/hooks';
import { fetcher } from '~/services/http';

const useFetch = <Data = any, Error = any, Payload = any>(
	url: string,
	axiosConfig?: AxiosRequestConfig<Payload>,
	swrConfig?: SWRConfiguration<Data, Error, BareFetcher<Data>>
) => {
	const resolved = useResolvedPath('/');
	const match = useMatch({ path: resolved.pathname, end: true });
	const push = useNavigate();
	const response = useSWR<Data, Error>(url, fetcher<Data, Payload>({ ...axiosConfig, url }), {
		onErrorRetry: (error, _key, { errorRetryInterval, errorRetryCount }, revalidate, { retryCount }) => {
			if ((error as any).status === 404) {
				if (!match) push('/');

				return;
			}

			if (retryCount >= (errorRetryCount ?? 10)) return;

			setTimeout(() => revalidate({ retryCount }), errorRetryInterval);
		},
		...swrConfig,
	});

	return response;
};

export default useFetch;

import { AppComponent } from '@fluencyacademy/core-components-web';

const MemorizationIcon: AppComponent = ({ className }) => (
  <svg className={className} width="44" height="51" viewBox="0 0 44 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M43.7001 33.8234L39.1051 24.8437C38.8811 24.3709 38.7689 23.7803 38.8811 23.1897C40.45 10.6652 30.6994 -0.677435 18.3711 0.0315653C8.95669 0.622165 1.11109 8.42016 0.102388 18.3448C-0.570112 25.4339 2.11969 31.8141 6.71479 36.0676C7.27499 36.5403 7.49939 37.2492 7.49939 38.076V48.4005C7.49939 49.8183 8.62029 51 9.96519 51H24.3107C25.6556 51 26.7765 49.8183 26.7765 48.4005V49.5371C26.7765 48.1193 27.8974 46.9376 29.2423 46.9376H36.6393C37.9842 46.9376 39.1051 45.756 39.1051 44.3381V40.3208C39.1051 38.903 40.226 37.7214 41.5709 37.7214C43.3635 37.7222 44.597 35.5956 43.7001 33.8234ZM33.8381 18.8177C33.2778 22.244 29.5793 24.0163 26.7772 22.0078C25.0961 23.1895 22.8547 23.1895 21.2854 21.7717C18.7078 22.7167 17.0266 25.3162 17.1384 28.1519L17.3624 32.996H12.9919C12.5434 30.5148 11.7591 28.388 9.29329 26.6158C8.28469 25.9068 7.27599 24.8435 7.16379 23.7801C6.26729 22.9529 4.69799 20.1173 6.71529 17.2816C5.93069 14.446 7.83619 12.2009 9.62919 11.6103C10.3016 9.83796 12.319 8.18396 15.0088 8.77466C17.3628 6.76616 19.7164 7.82956 20.3889 8.30226C23.415 6.88446 25.4323 8.53847 26.217 9.83837C28.4584 9.48397 30.588 11.4924 31.0364 13.6192C32.8294 14.3281 34.1743 16.4548 33.8381 18.8177Z"
      fill="currentColor"
    />
  </svg>
);

export { MemorizationIcon };

import { ReactNode } from 'react';

import { AppComponent } from '@fluencyacademy/core-components-web';
import { twMerge } from 'tailwind-merge';

import { CoursesIcon } from '../icons/courses-icon';
import { MemorizationIcon } from '../icons/memorization-icon';
import { TalksIcon } from '../icons/talks-icon';
import { HomeProduct } from '../types/product';

type ProductIconProps = {
  product: HomeProduct;
};

const ProductIcon: AppComponent<ProductIconProps> = ({ product, className }) => {
  let icon: ReactNode | undefined;
  let colorClass: string | undefined;

  switch (product) {
    case HomeProduct.COURSES:
      icon = (
        <CoursesIcon
          className={twMerge(
            'group-hover:text-brand-primary-cursos-700 text-basic-white-50 md:w-[52px] md:h-[43px] w-[23px] h-[19px]'
          )}
        />
      );
      colorClass = 'bg-brand-primary-cursos-700';
      break;
    case HomeProduct.MEMORIZATION:
      icon = (
        <MemorizationIcon
          className={twMerge(
            'group-hover:text-brand-primary-mem-700 text-basic-white-50 md:w-[52px] md:h-[43px] w-[23px] h-[19px]'
          )}
        />
      );
      colorClass = 'bg-brand-primary-mem-700';
      break;
    case HomeProduct.TALKS:
      icon = (
        <TalksIcon
          className={twMerge(
            'group-hover:text-brand-primary-talks-700 text-basic-white-50 md:w-[52px] md:h-[43px] w-[23px] h-[19px]'
          )}
        />
      );
      colorClass = 'bg-brand-primary-talks-700';
      break;
  }

  return (
    <div
      className={twMerge(
        'flex rounded-full md:w-[102px] md:h-[102px] w-[42px] h-[42px] items-center justify-center group-hover:bg-basic-white-50',
        [className, colorClass]
      )}
    >
      {icon}
    </div>
  );
};

export default ProductIcon;

import { createIcon } from '@chakra-ui/icons';

const Book = createIcon({
	displayName: 'Book',
	viewBox: '0 0 24 24',
	path: (
		<path
			d="M19.944 5.00196C18.232 5.10196 14.828 5.45296 12.727 6.73896C12.657 6.78238 12.5993 6.84309 12.5596 6.91525C12.5198 6.98741 12.4993 7.06858 12.5 7.15096V18.522C12.5039 18.6055 12.5287 18.6867 12.5721 18.7582C12.6155 18.8297 12.6761 18.8892 12.7485 18.9312C12.8208 18.9732 12.9025 18.9963 12.9861 18.9986C13.0697 19.0008 13.1525 18.982 13.227 18.944C15.3952 18.0382 17.7121 17.5412 20.061 17.478C20.3106 17.4688 20.5471 17.3642 20.7218 17.1858C20.8965 17.0073 20.9961 16.7687 21 16.519V5.96196C20.9975 5.82848 20.9678 5.69691 20.9127 5.57533C20.8575 5.45374 20.7781 5.3447 20.6794 5.2549C20.5806 5.16511 20.4645 5.09644 20.3382 5.05311C20.2119 5.00979 20.0781 4.99272 19.945 5.00296L19.944 5.00196ZM11.273 6.73996C9.173 5.45396 5.773 5.09996 4.056 5.00296C3.92281 4.99258 3.7889 5.00954 3.6625 5.0528C3.53611 5.09606 3.41989 5.1647 3.32099 5.25452C3.22209 5.34433 3.1426 5.45342 3.0874 5.57508C3.0322 5.69673 3.00246 5.82839 3 5.96196V16.52C3.00393 16.7694 3.10341 17.0078 3.27793 17.186C3.45245 17.3643 3.68871 17.4688 3.938 17.478C6.28797 17.5411 8.60598 18.0385 10.775 18.945C10.8491 18.9845 10.932 19.0045 11.016 19.003C11.1 19.0015 11.1821 18.9786 11.2548 18.9364C11.3274 18.8942 11.388 18.8342 11.4309 18.762C11.4739 18.6899 11.4976 18.6079 11.5 18.524V7.14596C11.5006 7.06441 11.48 6.9841 11.4402 6.91291C11.4004 6.84173 11.3428 6.78213 11.273 6.73996Z"
			fill="currentColor"
		/>
	),
});

export default Book;

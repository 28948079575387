import { AppComponent } from '@fluencyacademy/core-components-web';

import { useTranslations } from '../hooks/use-translations';
import { HomeProduct } from '../types/product';
import ProductContent from './product-content';
import ProductCoursesHighlightedText from './product-courses-highlighted-text';
import ProductTag from './product-tag';

const ProductCoursesContent: AppComponent = () => {
  const t = useTranslations('productsHub');

  return (
    <ProductContent
      product={HomeProduct.COURSES}
      title={t('courses_product.content_title')}
      description={t('courses_product.description')}
      backgroundColor="bg-brand-primary-700"
      mainImgSrc={'/images/courses_content.png'}
      extraHeader={<ProductTag label={t('courses_product.tag')} backgroundColor="bg-brand-primary-cursos-700" />}
      extraDescription={<ProductCoursesHighlightedText />}
    />
  );
};

export default ProductCoursesContent;

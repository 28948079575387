const purple = {
	50: '#EDE5FC',
	100: '#c7b8fb',
	200: '#a28bf5',
	300: '#6B47ED',
	400: '#572eea',
	500: '#3e15d0',
	550: '#332173',
	600: '#3010a3',
	700: '#220a75',
	800: '#130648',
	900: '#08011d',
};

export default purple;

import { Box, Flex, Image, ImageProps } from '@chakra-ui/react';

import { useProduct, useNavigate } from '~/hooks';

import { Header } from './components/';

export enum ContainerConfig {
	TORNPAPER = '/images/auth-tornpaper.svg',
}

export interface ContainerProps extends Omit<ImageProps, 'children' | 'color'> {
	children(goBack: () => void): React.ReactNode;
}

const Container: React.FC<ContainerProps> = ({ children, ...props }) => {
	const navigate = useNavigate();
	const product = useProduct();

	return (
		<Flex minHeight="100vh" position="relative" bgColor="gray.100" direction="column">
			<Header product={product} />
			<Flex
				width="inherit"
				gridArea="content"
				zIndex="10"
				px={['6', '9.375rem']}
				py={['4', '3.75rem']}
				paddingBottom={['6', '3.75rem']}
			>
				{children(() => navigate(-1))}
			</Flex>
			<Image objectFit="cover" position="fixed" top="0" left="0" height="100vh" width="100vw" {...props} />
			{!product?.id && (
				<Box
					as="footer"
					position="fixed"
					bottom="0"
					width="100vw"
					height="64"
					bgImage={`url('${ContainerConfig.TORNPAPER}')`}
					bgSize="cover"
					bgRepeat="no-repeat"
				/>
			)}
		</Flex>
	);
};

export default Container;

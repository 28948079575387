import useMutate from '~/hooks/useMutateApi';

export interface RequestProps {
	phone_number: string;
}

export interface ResponseProps {
	created: boolean;
}

const useSendPhoneCode = () => useMutate<ResponseProps, RequestProps>('/users/me/verify-code/phone-number/', { method: 'POST' });

export { useSendPhoneCode };

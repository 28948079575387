import { Box, Button, Flex, Image, Menu, MenuButton, MenuItem, MenuList, useDisclosure } from '@chakra-ui/react';

import { useNamespace } from '~/hooks';
import { ChevronDown, ChevronUp } from '~/theme/icons';

interface SimpleLanguageMenuProps {
  isTransparent?: boolean;
}

interface LanguageProps {
  language: string;
  key: string;
  flag: string;
}

const languages: Array<LanguageProps> = [
  { language: 'en', key: 'languageMenu@english', flag: '/images/flags/usa.png' },
  { language: 'es', key: 'languageMenu@spanish', flag: '/images/flags/spain.png' },
  { language: 'pt', key: 'languageMenu@portuguese', flag: '/images/flags/brazil.png' },
];

const SimpleLanguageMenu: ComponentWithoutChildren<SimpleLanguageMenuProps> = ({ isTransparent = true }) => {
  const { i18next, translate } = useNamespace('languageMenu');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const setDefaultLanguage = () => {
    i18next.changeLanguage('pt');

    return languages[2];
  };

  const selectedLanguage = languages.find(({ language }) => language === i18next.language) || setDefaultLanguage();

  return (
    <Menu onOpen={onOpen} onClose={onClose}>
      <MenuButton
        as={Button}
        height="2.75rem"
        backgroundColor={' transparent'}
        paddingX="6px"
        fontSize="sm"
        color={isTransparent ? 'purple.50' : 'gray.900'}
        textStyle="h6"
        variant="secondary"
        iconSpacing="4px"
        rightIcon={isOpen ? <ChevronUp color={'purple.50'} boxSize="24px" /> : <ChevronDown color={'purple.50'} boxSize="24px" />}
      >
        <Flex alignItems="center" gap="6px">
          <Image boxSize="6" src={selectedLanguage?.flag} />
          <span>{selectedLanguage.language.toUpperCase()}</span>
        </Flex>
      </MenuButton>
      <MenuList backgroundColor="#f0f0f0" borderRadius="1rem" minW="0" width="auto" color="gray.900" fontSize="sm">
        {languages.map(({ language, key, flag }) => {
          const isSelected = language === i18next.language;

          return (
            <MenuItem
              borderRadius="1rem"
              display="flex"
              gap="3"
              value={key}
              key={key}
              onClick={() => i18next.changeLanguage(language)}
            >
              <Box
                position="relative"
                height="5"
                width="5"
                borderRadius="50%"
                border="1px solid"
                borderColor={isSelected ? 'purple.400' : 'gray.400'}
                _before={
                  isSelected
                    ? {
                        content: '""',
                        display: 'inline-block',
                        position: 'absolute',
                        top: '3px',
                        left: '3px',
                        height: '3',
                        width: '3',
                        background: 'purple.400',
                        borderRadius: '50%',
                      }
                    : {}
                }
              />
              <Flex alignItems="center" gap="1">
                <Image boxSize="6" src={flag} />
                <span>{translate(key as 'languageMenu@english')}</span>
              </Flex>
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};

export default SimpleLanguageMenu;

import { useEffect } from 'react';

import { Box, ComponentWithAs, Flex, IconProps, theme } from '@chakra-ui/react';
import { useLocation, useMatch, useResolvedPath } from 'react-router-dom';

import { useNamespace, useNavigate } from '~/hooks';

export interface NavItemProps {
	Icon: ComponentWithAs<'svg', IconProps>;
	title: string;
	to: string | undefined;
	isMobile?: boolean;
}

const NavItem: ComponentWithAs<'div', NavItemProps> = ({ Icon, title, to, isMobile, ...props }) => {
	const navigate = useNavigate();
	const resolved = useResolvedPath(to as string);
	const { search } = useLocation();
	const match = useMatch({ path: resolved.pathname, end: true });
	const { translate } = useNamespace('pageLayout');

	const handleKeyPressed: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
		if (event.code === 'Enter') navigate(`${to}${search}`);
	};

	useEffect(() => {
		if (match) {
			document.title = `${title} | ${import.meta.env.VITE_APP_TITLE}`;
		}
	}, [match, title]);

	return (
		<Flex
			direction="row"
			alignItems="center"
			gridColumnGap="2"
			cursor="pointer"
			onClick={() => navigate(`${to}${search}`)}
			onKeyDown={handleKeyPressed}
			whiteSpace="nowrap"
			className="navitem"
			sx={
				isMobile
					? {
							px: '2.5',
							height: '100%',
							borderBottomWidth: match ? theme.sizes[1] : '0',
							borderBottomColor: match ? 'white' : 'transparent',
							marginBottom: '4',
					  }
					: {
							borderLeftWidth: match ? theme.sizes[1] : '0',
							borderLeftColor: match ? 'purple.300' : 'transparent',
							bgColor: match ? 'purple.50' : 'white',
							color: match ? 'purple.300' : 'gray.750',
							pointerEvents: match ? 'none' : 'auto',
							px: match ? '7' : '8',
							py: '1',
							height: '12',
					  }
			}
			{...props}
		>
			<Icon boxSize="6" />
			<Box textStyle="button.primary">{translate(title as 'pageLayout@products-title')}</Box>
		</Flex>
	);
};

NavItem.defaultProps = {
	isMobile: false,
};

export default NavItem;

import { useAtom } from 'jotai';
import { Outlet } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { useUser } from '~/api/users/retrieve';
import useAuth from '~/hooks/useAuth';

import { selectedProductAtom } from './atoms/selected-product-atom';
import { Navbar } from './components/navbar';
import SupportButton from './components/support-button';
import { HomeProduct } from './types/product';

function Layout() {
  const auth = useAuth();
  const { data } = useUser();

  const [selectedProduct] = useAtom(selectedProductAtom);
  const bgColor =
    selectedProduct === HomeProduct.COURSES
      ? 'bg-brand-primary-cursos-700'
      : selectedProduct === HomeProduct.MEMORIZATION
      ? 'bg-brand-primary-mem-700'
      : 'bg-brand-primary-talks-700';

  return (
    <body className={twMerge('flex flex-col w-full h-full md:h-screen relative transition-all duration-300', [bgColor])}>
      <header className="container flex items-center w-full max-w-none justify-center">
        <Navbar user={data} />
        <div className="fixed z-50 bottom-[8px] right-[12px] md:hidden">
          <SupportButton className="shadow" />
        </div>
      </header>
      <main className="flex-grow">
        <Outlet context={{ auth, user: data }} />
      </main>
    </body>
  );
}

export default Layout;

import { ComponentWithAs, Flex, FlexProps } from '@chakra-ui/react';

const Navbar: ComponentWithAs<'div', FlexProps> = ({ children, ...props }) => (
	<Flex
		position="fixed"
		top="16"
		left="0"
		height="12"
		bgColor="purple.300"
		color="white"
		overflowX="auto"
		zIndex="docked"
		width="100%"
		sx={{
			'&::-webkit-scrollbar': {
				width: 0,
				height: 0,
			},
		}}
		{...props}
	>
		<Flex direction="row" position="relative" px="6">
			{children}
		</Flex>
	</Flex>
);

export default Navbar;

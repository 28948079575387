import { useAtom } from 'jotai';
import { useEffectOnce } from 'react-use';

import useDevice from '~/hooks/useDevice';

import { selectedProductAtom } from '../atoms/selected-product-atom';
import { HomeProduct } from '../types/product';

const useProductList = () => {
  const [selectedProduct, setSelectedProduct] = useAtom(selectedProductAtom);
  const { isMobileDevice } = useDevice();

  useEffectOnce(() => {
    if (!isMobileDevice) {
      setSelectedProduct(HomeProduct.COURSES);
    }
  });

  return {
    selectedProduct,
    setSelectedProduct,
  };
};

export { useProductList };

import useMutate from '~/hooks/useMutateApi';

export interface ResponseProps {
	postal_code: string;
	address: string;
	neighborhood: string;
	city: string;
	state: string;
	country: string;
	complement: string;
}

export interface RequestProps {
	postal_code: string;
	address: string;
	neighborhood: string;
	city: string;
	state: string;
	country: string;
	complement: string;
}

const useUserAddress = () => useMutate<ResponseProps, RequestProps>('/users/me/address/', { method: 'PUT' });

export default useUserAddress;

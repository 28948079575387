import { useRef, useState } from 'react';

import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import * as Yup from 'yup';

import resetPassword from '~/api/auth/resetPassword';
import { usePromise } from '~/hooks';
import useNamespace from '~/hooks/useNamespace';
import { Alert, Password } from '~/theme/components';
import { ImperativeAlertProps } from '~/theme/components/Alert';
import { yupTest } from '~/utils/passVerify';

export interface ChangePasswordProps {
	oldPassword: string;
	newPassword: string;
	confirmPassword: string;
}

const schema = Yup.object({
	oldPassword: Yup.string()
		.required('changePassword@validation-current-password-required')
		.test(...yupTest('changePassword')),
	newPassword: Yup.string().test('not-same-password', 'changePassword@validation-new-password-invalid', function () {
		return this.parent.oldPassword !== this.parent.newPassword;
	}),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref('newPassword')], 'changePassword@validation-confirm-password-invalid')
		.required('changePassword@validation-confirm-password-required')
		.test(...yupTest('changePassword')),
}).required();

function ChangePassword() {
	const alertRef = useRef<ImperativeAlertProps>(null);
	const [status, setStatus] = useState<number | undefined>();

	const [request, { error, isLoading }] = usePromise(
		async (oldPassword: string, newPassword: string) =>
			await resetPassword.post({ previous_password: oldPassword, new_password: newPassword })
	);
	const { translate } = useNamespace('changePassword');

	const { formState, register, handleSubmit, reset } = useForm<ChangePasswordProps>({
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		criteriaMode: 'all',
		mode: 'all',
	});

	const onSubmit: SubmitHandler<ChangePasswordProps> = async ({ oldPassword, newPassword }) => {
		try {
			const [, response] = await request(oldPassword, newPassword);

			setStatus(response.status);
			reset();
		} finally {
			alertRef.current?.turnOnAlert();
		}
	};

	return (
		<Flex direction="column" gridRowGap="8" width="inherit">
			<Alert status={status === 200 ? 'success' : 'error'} ref={alertRef} autoDisplay={false}>
				{status === 200 ? translate('changePassword@success-message') : error?.message}
			</Alert>
			<Flex direction="column" alignItems="start" gridRowGap="4">
				<Box textStyle="h5" color="gray.900">
					{translate('changePassword@title')}
				</Box>
			</Flex>
			<Flex direction="column">
				<FormControl as="form" display="flex" flexDirection="column" gridRowGap="8" width="inherit" isInvalid={formState.isDirty}>
					<Flex direction="column" width="100%" alignItems="flex-start" gridRowGap="4">
						<Flex direction="column" width="inherit">
							<FormLabel htmlFor="oldPassword" textStyle="label.primary" color="black">
								{translate('changePassword@form-current-password-label')}
							</FormLabel>
							<Password
								id="oldPassword"
								data-testid="oldPassword"
								isInvalid={!!formState.errors.oldPassword}
								required
								{...register('oldPassword')}
							/>
							{formState.errors.oldPassword && (
								<FormErrorMessage>
									{translate(formState.errors.oldPassword.message as 'changePassword@validation-current-password-required')}
								</FormErrorMessage>
							)}
						</Flex>

						<Flex direction="column" width="inherit">
							<FormLabel htmlFor="newPassword" textStyle="label.primary" color="black">
								{translate('changePassword@form-new-password-label')}
							</FormLabel>
							<Password
								id="newPassword"
								data-testid="newPassword"
								isInvalid={!!formState.errors.newPassword}
								{...register('newPassword')}
								required
								hint
							/>
							{formState.errors.newPassword && (
								<FormErrorMessage>{translate('changePassword@validation-new-password-invalid')}</FormErrorMessage>
							)}
						</Flex>

						<Flex direction="column" width="inherit">
							<FormLabel htmlFor="confirmPassword" textStyle="label.primary" color="black">
								{translate('changePassword@form-confirm-password-label')}
							</FormLabel>
							<Password
								id="confirmPassword"
								data-testid="confirmPassword"
								isInvalid={!!formState.errors.confirmPassword}
								required
								{...register('confirmPassword')}
							/>
							{formState.errors.confirmPassword && (
								<FormErrorMessage>
									{translate(formState.errors.confirmPassword.message as 'changePassword@validation-confirm-password-required')}
								</FormErrorMessage>
							)}
						</Flex>
					</Flex>
					<Box>
						<Button
							type="submit"
							onClick={handleSubmit(onSubmit)}
							isDisabled={!(formState.isValid && formState.isDirty)}
							isLoading={isLoading}
							minWidth="32"
						>
							{translate('changePassword@form-save-password-button')}
						</Button>
					</Box>
				</FormControl>
			</Flex>
		</Flex>
	);
}

export default ChangePassword;

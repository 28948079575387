import { useMemo } from 'react';

import { FlexProps } from '@chakra-ui/react';

import { useDevice } from '~/hooks';

import Drawer from '../Drawer';
import LeftSideBar from '../LeftSideBar';
import Navbar from '../Navbar';

export interface NavigationProps extends Omit<FlexProps, 'children'> {
	isOpen: boolean;
	onClose(): void;
	children(isMobileDevice: boolean): JSX.Element;
}

const Navigation: ComponentWithoutChildren<NavigationProps> = ({ isOpen, onClose, children, ...props }) => {
	const { isMobileDevice } = useDevice();

	const childList = useMemo(() => children(isMobileDevice), [children, isMobileDevice]);

	const NavContainer = isMobileDevice ? Navbar : LeftSideBar;

	return (
		<>
			{!isOpen && <NavContainer {...props}>{childList}</NavContainer>}
			{isMobileDevice && (
				<Drawer onClose={onClose} isOpen={isOpen}>
					{childList}
				</Drawer>
			)}
		</>
	);
};

export default Navigation;

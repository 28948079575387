const castObject = <T extends { [key: string]: any }, K extends 'string' | 'number'>(object: T, type: K) => {
	const casted = {};

	for (const [key, value] of Object.entries(object)) {
		let castedValue!: any;

		switch (type) {
			case 'number':
				castedValue = Number(value);
				break;
			default:
				castedValue = String(value);
		}

		casted[key] = castedValue;
	}

	return casted as { [key: string]: K extends 'string' ? string : number };
};

export default castObject;

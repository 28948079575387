import { Button } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import useNamespace from '~/hooks/useNamespace';
import useNavigate from '~/hooks/useNavigate';

export default function SignOut() {
	const { search } = useLocation();
	const navigate = useNavigate();
	const { translate } = useNamespace('pageLayout');

	const signOut = () => {
		navigate(`/signout${search}`);
	};

	return (
		<Button color="red" variant="terciary" onClick={signOut}>
			{translate('pageLayout@exit-button')}
		</Button>
	);
}

const gray = {
	50: '#fbfbfb',
	100: '#f6f6f6',
	200: '#DBDBDB',
	300: '#c1bfbf',
	400: '#afafaf',
	500: '#716A6A',
	600: '#666666',
	700: '#413f40',
	750: '#3d3d3d',
	800: '#292526',
	900: '#1C1C1C',
};

export default gray;

import { useEffect, useReducer, useRef } from 'react';

import { AxiosRequestConfig } from 'axios';

import http from '~/services/http';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface State<T> {
	loading: boolean;
	data?: T;
	error?: Error;
}

type Action<T> = { type: 'loading' } | { type: 'fetched'; payload: T } | { type: 'error'; payload: Error };

type FetchOptions<T> = AxiosRequestConfig & {
	skipFetch?: boolean;
	initialValue?: object | Array<unknown> | string | number | T;
};

function useFetch<T = unknown>(url?: string, options?: FetchOptions<T>): State<T> {
	const cancelRequest = useRef<boolean>(false);
	const initialState: State<T> = {
		loading: false,
		error: {} as Error,
		data: (options?.initialValue || {}) as T,
	};

	const fetchReducer = (state: State<T>, action: Action<T>): State<T> => {
		switch (action.type) {
			case 'loading':
				return { ...initialState, loading: true };
			case 'fetched':
				return { ...initialState, data: action.payload, loading: false };
			case 'error':
				return { ...initialState, error: action.payload, loading: false };
			default:
				return state;
		}
	};

	const [state, dispatch] = useReducer(fetchReducer, initialState);

	useEffect(() => {
		if (!url) return;

		cancelRequest.current = false;

		const fetchData = async () => {
			dispatch({ type: 'loading' });

			try {
				const response = await http({ url, ...options });

				if (response.status > 300) {
					throw new Error(response.statusText);
				}

				if (cancelRequest.current) return;

				dispatch({ type: 'fetched', payload: response.data });
			} catch (error) {
				if (cancelRequest.current) return;

				dispatch({ type: 'error', payload: error as Error });
			}
		};

		if (!options?.skipFetch) {
			void fetchData();
		}

		return () => {
			cancelRequest.current = true;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [url, options?.skipFetch]);

	return state;
}

export default useFetch;

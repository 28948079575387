export interface BirthdateProps {
	day: string;
	month: string;
	year: string;
}

const birthdateValidator = (birthdate: BirthdateProps) => {
	const date = Object.values(birthdate);
	const isBeeingFilled = date && !!date.find((value) => typeof value === 'string');
	const isFilled = date && date.find((value) => value === null || value === '') === undefined;

	return { isFilled, isBeeingFilled };
};

export default birthdateValidator;

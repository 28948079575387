import { AppComponent } from '@fluencyacademy/core-components-web';

const FluencyIcon: AppComponent = ({ className }) => (
  <svg className={className} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="25" height="25" rx="4" fill="#6B47ED" />
    <path
      d="M15.867 6.48838V3.3125H13.3263C10.5386 3.3125 8.5625 5.5709 8.5625 8.18218C8.5625 9.24081 8.95066 11.0758 10.6445 11.8874C8.95066 12.5578 8.5625 14.1105 8.5625 15.4514V21.4856H11.7384V15.6278C11.7384 14.2869 12.4441 13.5459 13.6792 13.5459H15.3377V10.37H13.6792C12.5147 10.37 11.7384 9.59368 11.7384 8.3939C11.7384 7.2647 12.5147 6.48838 13.6792 6.48838H15.867Z"
      fill="white"
    />
  </svg>
);

export { FluencyIcon };
